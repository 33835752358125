import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { headerWithAuth, handleUnAutherize } from 'lib/authFunc'
import * as actions from './actions'

const  header = headerWithAuth()

function* loadBatches(action) {
  try {
    const { term } = action.payload
    const res = yield call(
      axios.get,
      `api/batches${term}`,
      header
    )
    yield put(actions.loadBatches.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadBatches.failure(error.response))
  }
}

function* editBatch(action) {
  try {
    const { batch, id } = action.payload
    const res = yield call(axios.patch, `/api/batches/${id}`, batch, header)

    yield put(actions.editBatch.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.editBatch.failure(error.response))
  }
}

export default function* watchComments() {
  yield all([
    takeEvery(actions.LOAD_BATCHES.REQUEST, loadBatches),
    takeEvery(actions.EDIT_BATCH.REQUEST, editBatch),
  ])
}
