import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { push } from 'connected-react-router'
import { headerWithAuth, handleUnAutherize } from 'lib/authFunc'
import * as actions from './actions'

const  header = headerWithAuth()

function* loadMobileGroups(action) {
  try {
    const { term } = action.payload
    const res = yield call(
      axios.get,
      `api/user-groups-mobile${term}`,
      header
    )
    yield put(actions.loadMobileGroups.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadMobileGroups.failure(error.response))
  }
}

function* loadMobileGroup(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.get, `/api/user-groups-mobile/${id}`, header)
    yield put(actions.loadMobileGroup.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadMobileGroup.failure(error.response))
  }
}

function* createMobileGroup(action) {
  try {
    const { mobilegroup } = action.payload
    const res = yield call(axios.post, '/api/user-groups-mobile', { mobilegroup }, header)
    yield put(push(`/master-data/mobile-groups/${res.data.id}`))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.createMobileGroup.failure(error))
  }
}

function* editMobileGroup(action) {
  try {
    const { mobilegroup, id } = action.payload
    const res = yield call(axios.patch, `/api/user-groups-mobile/${id}`, mobilegroup, header)

    yield put(actions.editMobileGroup.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.editMobileGroup.failure(error.response))
  }
}

function* deleteMobileGroup(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.delete, `/api/user-groups-mobile/${id}`, header)
    yield put(actions.deleteMobileGroup.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.deleteMobileGroup.failure(error.response))
  }
}


export default function* watchComments() {
  yield all([
    takeEvery(actions.LOAD_MOBILEGROUPS.REQUEST, loadMobileGroups),
    takeEvery(actions.LOAD_MOBILEGROUP.REQUEST, loadMobileGroup),
    takeEvery(actions.CREATE_MOBILEGROUP.REQUEST, createMobileGroup),
    takeEvery(actions.EDIT_MOBILEGROUP.REQUEST, editMobileGroup),
    takeEvery(actions.DELETE_MOBILEGROUP.REQUEST, deleteMobileGroup)
  ])
}
