import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Input from '@material-ui/core/Input';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

import { getIdtoArray, dataFilter } from 'lib/helper'

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 200
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular
  },
  checkbox: {
    padding: '4px'
  }
})

function CheckboxList(props) {
  const { classes, onChange, list, title, checkList, displayName } = props
  const [items, setValue] = useState([])
  const [top, setCheckValueTop] = useState(false)
  const [isLoading, setLoad] = useState(false)
  const [search, setSearch] = useState('')
 
  const setItem = list => {
    setValue(list)
    setLoad(true)
  }
  
  useEffect(() => {
    if(typeof list.data !== 'undefined' && items.length === 0 && isLoading === false) 
      setItem(list.data)
  })

  const getHasItemTop = (items, checkList) => {
    let yes = [], no = []
    if(typeof items !== 'object') return
    items.map(item => {
      let id = parseInt(item.id)
      if (typeof checkList !== 'object') return true

      if (checkList.includes(id)) {
        yes = [...yes, item]
      } else {
        no = [...no, item]
      }
    })

    return top ? [...yes, ...no] : items
  }

  const handleClickOnlySelect = () => {
    setCheckValueTop(!top)
  }

  return (
    <Fragment>
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>{title}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Input
                placeholder="ค้นหา..."
                fullWidth
                onChange={e => {
                  setSearch(e.target.value)
                  setItem(dataFilter(list.data, e.target.value, typeof displayName === 'undefined' ? 'name' : displayName))
                }}
                endAdornment={
                  <InputAdornment position="end" style={{ marginBottom: '5px' }}>
                    <IconButton onClick={handleClickOnlySelect} style={{ paddingRight: '0px' }}>
                      {top ? <CheckCircle color="primary"/> : <CheckCircleOutline />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <List className={classes.root}>
                <ListItem dense button>
                  <Checkbox
                    onClick={e => onChange(e, getIdtoArray(list.data))}
                    className={classes.checkbox}
                    checked={
                      typeof checkList === 'object' &&
                      typeof list.data === 'object' &&
                      list.data.length === checkList.length
                    }
                    value={'0'}
                    color="primary"
                  />
                  <ListItemText primary="เลือกทั้งหมด" />
                </ListItem>
                {typeof items === 'object' &&
                  getHasItemTop(items, checkList).map(item => {
                    let display =
                      typeof displayName === 'undefined' ? 'name' : displayName
                    let id = parseInt(item.id)

                    return (
                      <ListItem key={item.id} dense button >
                        <Checkbox
                          className={classes.checkbox}
                          checked={
                            typeof checkList === 'object' &&
                            checkList.includes(id)
                          }
                          value={item.id.toString()}
                          onClick={e => onChange(e, id)}
                          tabIndex={-1} disableRipple />
                        <ListItemText primary={item[`${display}`]} />
                      </ListItem>
                    )
                  })}
              </List>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Fragment>
  )
}

CheckboxList.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CheckboxList)
