
import React, { Fragment } from 'react'
import classNames from 'classnames'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/PowerSettingsNew';
import MailIcon from '@material-ui/icons/Mail';
import MoreIcon from '@material-ui/icons/MoreVert';

import { useStyles } from './AddonStyle'
import { trackPromise } from 'react-promise-tracker';

import NotiList from './noti/NotiList'
import NotiBadge from './noti/NotiBadge'

import {
  destroyToken,
  headerWithAuth,
  getUser,
  getUserFullname,
  config,
  checkPermission
} from 'lib/authFunc'

import axios from 'axios'
import { COMPANY_NAME } from 'lib/defaultSystemData'

export default ({ open, handleDrawerOpen }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [newMsg, setHasNewMsg] = React.useState(false);
  const [noti, setNoti] = React.useState([]);

  const [loadingProgress, setLoadingProgress] = React.useState(0);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  async function logOut() {
    try {
      const user = getUser()
      await axios.post(`/api/logout`, { token: user.token }, headerWithAuth)
      destroyToken()
      window.location.href = '/'
    } catch (err) {
      destroyToken()
    }
  }

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
    setHasNewMsg(false)
    sendNoti()
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
  }

  function sendNoti() {
    setLoadingProgress(25)
    trackPromise(
      axios.get(`/api/noti?limit=10`, config).then(data => {
        setNoti(data.data.data)
        setLoadingProgress(50)
      })
    )
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  const handleDelete = (id) => {
    let res = axios.delete(`/api/noti/${id}`, config)
    res.then(data => {
      if (data.status === 200) sendNoti()
    })
  }

  const handleUpdate = (id, status) => {
    let res = axios.put(`/api/noti/${id}`, { status: status }, config)
    res.then(data => {
      if (data.status === 200) sendNoti()
    })
  }

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <NotiList data={noti}
        onDelete={handleDelete}
        onUpdate={handleUpdate}
        loadingProgress={loadingProgress}
      />
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {checkPermission('notification') &&
        <MenuItem>
          <IconButton color="inherit" component="a" href="/notification">
            <Badge badgeContent={newMsg ? "New" : "0"} color="secondary">
              <MailIcon />
            </Badge>
          </IconButton>
          <p>แจ้งเตือน</p>
        </MenuItem>
      }
      <MenuItem onClick={logOut}>
        <IconButton color="inherit">
          <AccountCircle />
        </IconButton>
        <p>ออกจากระบบ</p>
      </MenuItem>
    </Menu>
  );
  return (
    <Fragment>
      <AppBar position="absolute"
        className={classNames(
          classes.appBar,
          open && classes.appBarShift
        )}>
        <Toolbar
          disableGutters={!open}
          className={classes.toolbar}>
          <IconButton className={classes.menuButton}
            color="inherit"
            onClick={handleDrawerOpen}
            className={classNames(
              classes.menuButtonHeader,
              open && classes.menuButtonHidden
            )} >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.titleHeader} variant="h6" color="inherit" noWrap id="team-label">
            {COMPANY_NAME} Bisimo Intelsales
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {checkPermission('notification') &&
              <IconButton color="inherit" onClick={handleProfileMenuOpen}>
                <NotiBadge />
              </IconButton>}
            <Typography variant="h6" color="inherit" noWrap id="team-label" style={{ fontSize: '14px', fontWeight: '100', marginTop: '15px' }}>
              {getUserFullname()}
            </Typography>
            <IconButton
              onClick={logOut}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton aria-haspopup="true" onClick={handleMobileMenuOpen} color="inherit">
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
      {renderMobileMenu}
    </Fragment>
  );
}
