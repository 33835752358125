import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { headerWithAuth, handleUnAutherize } from 'lib/authFunc'
import * as actions from './actions'

const header = headerWithAuth()

function* loadCustomerSummary(action) {
  try {
    const { term } = action.payload
    const res = yield call(
      axios.post,
      `api/analysis/customer`,
      term,
      header
    )
    yield put(actions.loadCustomerSummary.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadCustomerSummary.failure(error.response))
  }
}

function* loadCompared(action) {
  try {
    const { term } = action.payload
    const res = yield call(
      axios.post,
      `api/analysis/sales-compared`,
      term,
      header
    )
    yield put(actions.loadCompared.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadCompared.failure(error.response))
  }
}

function* loadCustomerAnalysis(action) {
  try {
    const { term } = action.payload
    const res = yield call(
      axios.post,
        `api/analysis/markermap`,
        term,
        header
    )
    yield put(actions.loadCustomerAnalysis.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadCustomerAnalysis.failure(error.response))
  }
}

function* loadCustomerGrowthRate(action) {
  try {
    const { term } = action.payload
    const res = yield call(
      axios.get,
      `api/analysis/line${term}`,
      header
    )
    yield put(actions.loadCustomerGrowthRate.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadCustomerGrowthRate.failure(error.response))
  }
}

function* loadPurchasingSummary(action) {
  try {
    const { term } = action.payload
    const res = yield call(
      axios.post,
      `api/analysis/purchasing`,
      term,
      header
    )
    yield put(actions.loadPurchasingSummary.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadPurchasingSummary.failure(error.response))
  }
}

function* loadTopspend(action) {
  try {
    const { term } = action.payload
    const res = yield call(
      axios.post,
      `api/analysis/topspend`,
      term,
      header
    )
    yield put(actions.loadTopspend.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadTopspend.failure(error.response))
  }
}

function* loadNewTopSpend(action) {
  try {
    const { term } = action.payload
    const res = yield call(
      axios.post,
      `api/analysis/new-topspend`,
      term,
      header
    )
    yield put(actions.loadNewTopSpend.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadNewTopSpend.failure(error.response))
  }
}


function* loadLowerSpend(action) {
  try {
    const { term } = action.payload
    const res = yield call(
      axios.post,
      `api/analysis/lowerspend`,
      term,
      header
    )
    yield put(actions.loadLowerSpend.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadLowerSpend.failure(error.response))
  }
}

export default function* watchComments() {
  yield all([
    takeEvery(actions.LOAD_CUSTOMER.REQUEST, loadCustomerSummary),
    takeEvery(actions.LOAD_COMPARED.REQUEST, loadCompared),
    takeEvery(actions.LOAD_MAP.REQUEST, loadCustomerAnalysis),
    takeEvery(actions.LOAD_LINE.REQUEST, loadCustomerGrowthRate),
    takeEvery(actions.LOAD_PURCHASE.REQUEST, loadPurchasingSummary),
    takeEvery(actions.LOAD_TOPSPEND.REQUEST, loadTopspend),
    takeEvery(actions.LOAD_NEW_TOPSPEND.REQUEST, loadNewTopSpend),
    takeEvery(actions.LOAD_LOWERSPEND.REQUEST, loadLowerSpend),
  ])
}
