import React, { useEffect, useState, Fragment } from 'react';
import MUIDataTable from "mui-datatables";

import { getIdtoArray } from 'lib/helper'

const columns = [
  { label: "ชื่อ", name: 'name' }, 
  { label: "Rack (X)", name: 'row_x' }, 
  { label:"Level (Y)", name: 'row_y' }, 
  { label:"Block (Z)", name: 'row_z' }
];

const MyCustomToolbarSelect = selectedRows => (
  <div></div>
)

function DocItems (props) {
  const { data, handleChecked, selectItem, warehouse_id } = props
  const [items, setItems] = useState([])
  const [warehouseId, setWarehouse] = useState('')

  const checkLot = (value) => {
    handleChecked('bin', value)
  }

  const setRowSelect = () => {
    let itemId = getIdtoArray(selectItem, 'bin_id')
    let res = []
    data.filter((item, i) => {
      if(itemId.includes(item.id)) {
        return res = [...res, i]
      }
    })

    return res
  }

  useEffect(() => {
    if (warehouseId !== warehouse_id && data.length > 0) {
      setItems(data)
      setWarehouse(warehouse_id)
    } 
  })

  const options = {
    filterType: 'multiselect',
    print: false,
    download: false,
    viewColumns: false,
    elevation: 1,
    customToolbarSelect: selectedRows => (
      <MyCustomToolbarSelect
        selectedRows={selectedRows}
      />
    ),
    rowsSelected: setRowSelect(),
    onRowsSelect: (currentRowsSelected, allRowsSelected) => {
      let itemId = getIdtoArray(allRowsSelected, 'dataIndex')
      let res = data.filter((item, i) => {
        return itemId.includes(i)
      })
      checkLot(res)
    }
  }

  return (
    <Fragment>
    {warehouseId !== warehouse_id && <div className="lmask" id="lmask" />}
      <MUIDataTable
        title={"Storage Bin"}
        data={data}
        columns={columns}
        options={options}
      />
    </Fragment>
  )
}

export default DocItems
