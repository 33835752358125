import React, { Fragment } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import MUIDataTable from "mui-datatables";
import { numberFormat } from 'lib/helper'
import { AnalysisTablestyle } from '../../AddonStyles'
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloudDownload from "@material-ui/icons/CloudDownload";
import { serializeObj } from 'lib/helper'
import { API_URL, FORM_URL } from 'lib/defaultSystemData'

const columns = [
    {
        name: "sales",
        label: "เซลล์",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "name",
        label: "ร้านค้า",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "shop_type",
        label: "ประเภท",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "start_date",
        label: "เข้าร่วม",
        options: {
            filter: false,
            sort: true,
        }
    }
];

class CompareTable extends React.Component {
    state = {
        isLoading : false
    }
    genHeader = () => {
        let except = [
            'initial', 'compare', 'diff', 
            'q1_1', 'q1_2', 'q1_diff',
            'q2_1', 'q2_2', 'q2_diff',
            'q3_1', 'q3_2', 'q3_diff',
            'q4_1', 'q4_2', 'q4_diff',
        ]
        let diff = [
            'diff', 'q1_diff', 'q2_diff', 'q3_diff', 'q4_diff'
        ]
        return [...columns, ...this.props.column].map(data => {
            if (except.includes(data.name)) {
                return {
                    ...data, options: {
                        filter: false,
                        sort: true,
                        customBodyRender: (value) => {
                            if(diff.includes(data.name)) {
                                if (value < 0) return <span style={{ color: 'red', fontWeight: 'bold' }}>{ numberFormat(value) }</span>
                                else if (value > 0) return <span style={{ color: 'green', fontWeight: 'bold' }}>+{ numberFormat(value) }</span>
                                else return numberFormat(value)
                            }
                            return numberFormat(value) 
                        }
                    }
                }
            } else {
                return { ...data }
            }
        })
    }

    createForm = () => {
    return <form action={`${FORM_URL}/api/report/sales-comparision`} method="post" id="form-data-1">
            <input type="hidden" value={serializeObj(this.props.cond)} name="data" />
        </form>
    }

    handleExport = async () => {
        document.getElementById('form-data-1').submit()
    }
    
    render() {
        const { data } = this.props;
        const options = {
            rowsPerPageOptions: [10, 100, 200],
            filter: true,
            viewColumns: false,
            rowsPerPage: 10,
            print: false,
            selectableRows: false,
            download: false,
            customSort: (data, colIndex, order) => {
                return data.sort((a, b) => {
                    a = a.data[colIndex] || '';
                    b = b.data[colIndex] || '';
                    if (order === 'asc') {
                        return a - b || a.toString().localeCompare(b, undefined, { sensitivity: 'base' });
                    } else if (order === 'desc') {
                        return b - a || b.toString().localeCompare(a, undefined, { sensitivity: 'base' });
                    }
                });
            },
            customToolbar: () => {
                return (
                    <Tooltip title="ดาวน์โหลด Excel" >
                        <IconButton onClick={this.handleExport}>
                            <CloudDownload />
                        </IconButton>
                    </Tooltip>
                );
            }
        }

        return (
            <Fragment>
                <MuiThemeProvider theme={AnalysisTablestyle}>
                    <ThemeProvider theme={AnalysisTablestyle}>
                        {this.state.isLoading && <div className="lmask" id="lmask" />}
                        <MUIDataTable
                            title={`รายการ`}
                            data={data.data}
                            columns={this.genHeader()}
                            pagination={false}
                            options={options}
                        />
                    </ThemeProvider>
                </MuiThemeProvider>
                { this.createForm() }
            </Fragment>
        );
    }
}

CompareTable.defaultProps = {
    data: {
        data: []
    },
    column: []
};

export default CompareTable;
