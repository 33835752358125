import React, { Component } from 'react'
import axios from 'axios'
import { Formik, FieldArray } from 'formik'
import * as yup from 'yup'
import { getIdtoArray } from 'lib/helper'
import { config } from 'lib/authFunc'
import { defaultState } from '../defaultState'

import { CheckboxList, SubmitBtn,  PageTitle } from 'lib/widgets'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import { Formstyles } from './AddonStyles'


class WebGroupForm extends Component {
  _isMounted = false

  state = defaultState

  getUserGroupMobile = async () => {
    try {
      const web = axios.get('/api/master-data/permissions', config)
      const user = axios.get('/api/master-data/users', config)

      const [users, groupWeb] = await Promise.all([user, web])

      if (this._isMounted) {
        this.setState({
          webGroup: groupWeb.data,
          users: users.data,
          formLoading: false
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  toggleFx = name => {
    let isOpen = this.state[`${name}`]
    this.setState({ [`${name}`]: !isOpen })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.disableForm === false &&
      window.location.pathname !== '/master-data/web-groups/new'
    ) {
      this.disableForm(true)
    }
  }

  componentDidMount() {
    this._isMounted = true
    this.getUserGroupMobile()
    if (window.location.pathname !== '/master-data/web-groups/new') {
      this.disableForm(true)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  disableForm = disableForm => {
    this.setState({ disableForm })
  }

  render() {
    const { classes, webgroup, onSubmit, isLoading } = this.props
    webgroup.checkWebList =
      typeof webgroup.relations.permission !== 'undefined' ?
      getIdtoArray(webgroup.relations.permission) : []
    webgroup.checkUserList =
      typeof webgroup.relations.users !== 'undefined' ?
      getIdtoArray(webgroup.relations.users) : []

    return (
      <Grid item xs={12}>
       { this.state.formLoading || isLoading ? <div className="lmask" id="lmask" /> : null }
        <Formik
          enableReinitialize
          initialValues={webgroup}
          validationSchema={yup.object().shape({
            name: yup.string().required('กรุณากรอก'),
            checkWebList: yup
              .array()
              .nullable()
              .required('กรุณาเลือกอย่างน้อย 1 สิทธิ์'),
            checkUserList: yup
              .array()
              .nullable()
              .required('กรุณาเลือกอย่างน้อย 1 ผู้ใช้งาน')
          })}
          onSubmit={ (values, { resetForm }) => {
            onSubmit(values)
            if (values.id === '') 
            resetForm(defaultState)
          }}
          render={({
            values,
            errors,
            touched,
            resetForm,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
          }) => (
            <Paper className={classes.paper}>
              <form onSubmit={handleSubmit}>
                <fieldset disabled={this.state.disableForm}>
                  <div className={classes.root}>
                    <Grid container spacing={24}>
                      <PageTitle
                        formTitle="กลุ่มสิทธิ์เว็บ"
                        action={this.disableForm}
                        edit={this.state.disableForm}
                        id={values.id}
                        view="groupmobile edit"
                      />
                      <Grid item xs={12}>
                        <TextField
                          required
                          name="name"
                          label="ชื่อ"
                          disabled={this.state.disableForm}
                          fullWidth
                          value={values.name || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.name && errors.name && (
                          <span className="form-text--error">
                            {errors.username}
                          </span>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="secondary"
                              name="active"
                              value="1"
                              onChange={(e) => {
                                let val = (e.target.checked) ? '1' : '0'
                                setFieldValue("active", val)
                              }}
                              onBlur={handleBlur}
                              checked={
                                values.active.toString() === '1'
                              }
                            />
                          }
                          label="Active"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {touched.checkWebList && errors.checkWebList && (
                          <span className="form-text--error">
                            {errors.checkWebList}
                          </span>
                        )}
                        <FieldArray
                          name="checkWebList"
                          render={arrayHelpers => (
                            <CheckboxList
                              title="สิทธิ์เว็บ *"
                              list={this.state.webGroup}
                              onChange={(e, id) => {
                                if (e.target.checked) {
                                  if (typeof id === 'object') {
                                    setFieldValue('checkWebList', id)
                                  } else {
                                    arrayHelpers.push(id)
                                  }
                                } else {
                                  if (typeof id === 'object') {
                                    setFieldValue('checkWebList', [])
                                  } else {
                                    const idx = values.checkWebList.indexOf(id)
                                    arrayHelpers.remove(idx)
                                  }
                                }
                              }}
                              checkList={values.checkWebList}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {touched.checkUserList && errors.checkUserList && (
                          <span className="form-text--error">
                            {errors.checkUserList}
                          </span>
                        )}
                        <FieldArray
                          name="checkUserList"
                          render={arrayHelpers => (
                            <CheckboxList
                              title="ผู้ใช้งาน *"
                              displayName="username"
                              list={this.state.users}
                              onChange={(e, id) => {
                                if (e.target.checked) {
                                  if (typeof id === 'object') {
                                    setFieldValue('checkUserList', id)
                                  } else {
                                    arrayHelpers.push(id)
                                  }
                                } else {
                                  if (typeof id === 'object') {
                                    setFieldValue('checkUserList', [])
                                  } else {
                                    const idx = values.checkUserList.indexOf(id)
                                    arrayHelpers.remove(idx)
                                  }
                                }
                              }}
                              checkList={values.checkUserList}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <SubmitBtn
                          disabled={this.state.disableForm}
                          onClear={() => resetForm(defaultState)}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </fieldset>
              </form>
            </Paper>
          )}
        />
      </Grid>
    )
  }
}
export default withStyles(Formstyles)(WebGroupForm)