import React, { useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import CalendarHeatmapApp from './CalendarHeatMap'

import SalesOrderTable from './../../salesorder/components/SalesOrderTable'
import { DatePickerWidget } from 'lib/widgets'
import axios from 'axios'
import { config } from 'lib/authFunc'
import { getFirstAndLastDate, getUrlLastSegment, formatDate, numberFormat } from 'lib/helper'

import 'react-calendar-heatmap/dist/styles.css';

const History = ({ classes }) => {
    const [isLoading, setLoadIndecator] = useState(true)
    const [data, setData] = useState([])
    const [start_date, setStartDate] = useState(getFirstAndLastDate(null, true, 'Y-m-d'))
    const [end_date, setEndDate] = useState(getFirstAndLastDate(null, false, 'Y-m-d'))
    const [customer_id, setCustomerId] = useState(getUrlLastSegment())
    const [cond, setCond] = useState('')
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue)
        fetchData(newValue)
    }



    const fetchData = async (newValue) => {
        setLoadIndecator(false) 
        setCustomerId(getUrlLastSegment())

        const data = {
          start_date: start_date,
          end_date: end_date,
          customer_id: customer_id,
          isCancel: newValue
        }

        setCond(data)

        const result = await axios.post('api/customer-history', data, config)
        if (typeof result.data !== 'undefined') {
            setData(result.data)
        }
    
    }
    const clearValue = () => {
        setCustomerId(getUrlLastSegment())
        setStartDate(getFirstAndLastDate(null, true, 'Y-m-d'))
        setEndDate(getFirstAndLastDate(null, false, 'Y-m-d'))
    }
    

    useEffect(() => {
        if (isLoading || customer_id !== getUrlLastSegment()) fetchData(0)
    })

    return <Paper className={classes.paper}>
        <Grid container justify="space-evenly" spacing={16}>
            <Grid item sm={3}>
                <DatePickerWidget 
                    name="start_date"
                    value={start_date}
                    title="วันทีเรื่มต้น"
                    onValueChange={(value, name) => {
                        setStartDate(formatDate(value))
                    }}
                />
            </Grid>
            <Grid item sm={3}>
                <DatePickerWidget 
                    name="end_date"
                    value={end_date}
                    title="วันที่สิ้นสุด"
                    onValueChange={(value, name) => {
                        setEndDate(formatDate(value))
                    }}
                />
            </Grid>
        </Grid>
        <Grid container justify="center">
              <Button variant="contained"
               style={{ margin: '10px' }}
               onClick={clearValue}>
                เคลียร์
              </Button>
              <Button variant="contained" 
                color="primary" style={{ margin: '10px' }}
                onClick={() => fetchData(0)}
                >
                ค้นหา
              </Button>
         </Grid>
         <Divider style={{ marginTop: '10px', marginBottom: '35px'}}/>
        <Grid container justify="center" spacing={16}>
            <Grid item md={6} xs={12}>
                <Typography variant="body1" >
                ยอดซื้อสะสมรวม
                </Typography>
                <Typography variant="h6" >
                { numberFormat(data.net) }
                </Typography>

                <Typography variant="h6" >
                    <LinearProgress variant="determinate" value={typeof data.avg_net !== 'number' ? 0 : data.avg_net} />
                </Typography>

                <Typography variant="body1" >
                { data.avg_net }% ของยอดขายทั้งหมด
                </Typography>
                <Typography variant="body1" >
                { data.net_per_month } บาท ต่อเดือน(Average)
                </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
                <Typography variant="body1" >
                จำนวน ORDERS สะสมรวม
                </Typography>
                <Typography variant="h6" >
                    { data.so_qty }
                </Typography>

                <Typography variant="h6" >
                    <LinearProgress color="secondary" variant="determinate" value={typeof data.avg_qty !== 'number' ? 0 : data.avg_qty} />
                </Typography>
                
                <Typography variant="body1" >
                { data.avg_qty }% ของ Sale Orders ทั้งหมด
                </Typography>
                <Typography variant="body1" >
                { data.qty_per_month } ต่อเดือน(Average)
                </Typography>
            </Grid>
        </Grid>
        <Grid container style={{ marginTop: '30px', marginBottom: '30px' }}>
            <Grid item xs={12}>
                <CalendarHeatmapApp 
                    cond={cond}
                />
            </Grid>
        </Grid>
        { window.innerWidth > 700 && 
        <Grid container>
            <Grid item xs={12}>
                <AppBar position="static">        
                    <Tabs value={value} onChange={handleChange} >
                        <Tab label="รายการขาย" />
                        <Tab label="รายการที่ยกเลิก" />
                    </Tabs>
                </AppBar>
                <SalesOrderTable 
                    page="history"
                    title={(value === 0) ? 'รายการขาย' : 'รายการที่ยกเลิก'}
                    pageCond={cond} />
            </Grid>
        </Grid>
        }
    </Paper>
}

export default History