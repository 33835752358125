import { createMuiTheme } from '@material-ui/core/styles';

const drawerWidth = 64

const Layoutstyles = theme => ({
    root: {
      flexGrow: 1
    }
})

const Tablestyle = () => createMuiTheme({
  overrides: {
    MuiPaper: {
      rounded: {
        padding: '5px'
      }
    },
    MuiTableCell: {
      root: {
        padding: '0px 0px 0px 4px !important',
      },
      body: {
        color: 'rgba(47, 47, 47, 0.87)',
        fontSize: '0.85rem',
        fontWeight: '100'
      },
      head: {
        color: 'rgba(0, 0, 0, 0.7)',
        fontSize: '0.85rem',
        fontWeight: '100',
      }
    },
    MuiTableRow: {
      head: {
        height: '30px'
      }
    },
    typography: {
      useNextVariants: true,
    },
    MuiIconButton: {
      root: {
        padding: '5px'
      }
    }
  }
})

const EditTableStyle = () => createMuiTheme({
  overrides: {
    TableContainer: {
      root: {
        height: '350px !important'
      }
    },
    EditColumn: {
      headingCell: {
        width: '120px'
      },
      cell: {
        width: '120px'
      }
    },
    MuiTableCell: {
      root: {
        padding: '0px 5px 0px 5px !important',
        borderRight: '0.5px solid rgba(224, 224, 224, 1)',
        borderLeft: '0.5px solid rgba(224, 224, 224, 1)',
      },
      body: {
        color: 'rgba(47, 47, 47, 0.87)',
        fontSize: '0.85rem',
        fontWeight: '100'
      },
      head: {
        color: 'rgba(0, 0, 0, 0.7)',
        fontSize: '0.85rem',
        fontWeight: '100',
      }
    },
    MuiTableRow: {
      head: {
        height: '43px',
        backgroundColor: '#89bdff'
      },
      root: {
        height: '43px',
        '&:nth-of-type(even)': {
          backgroundColor: '#dce5e852',
        }
      }
    },
    MuiInput: {
      root: {
        marginBottom: '35px'
      }
    },
    MuiButton: {
      textPrimary: {
        color: '#ffffff'
      },
      text: {
        background: '#fb8c00'
      }
    },
    MuiInputBase: {
      root: {
        paddingBottom: '0px',
        paddingTop: '9px'
      }
    },
    MuiIconButton: {
      root: {
        padding: '5px'
      }
    },
    MuiSvgIcon: {
      root: {
        width: '0.75em',
        height: '0.75em'
      }
    }
  },
  typography: { useNextVariants: true },
})

export {
    Layoutstyles,
    Tablestyle,
    EditTableStyle
}