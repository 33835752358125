import React, { useState, useEffect, Fragment, useRef } from 'react';

import Grid from '@material-ui/core/Grid';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

import SalesOrder from './SalesOrder'

import axios from 'axios'
import { config, handleUnAutherize } from 'lib/authFunc'
import { formatDate, getFirstAndLastDate } from 'lib/helper'
import { Loading } from 'lib/widgets'

import $ from 'jquery'

export default ({ classes, companyId, branchId, MonthId }) => {
    const calendarComponentRef = useRef(null);
    const [data, setData] = useState([])
    const [salesOrder, setSaleOrders] = useState([])
    const [isLoading, setLoad] = useState(false)
    const [loadIndecator, setLoadIndecator] = useState(true)
    const [currentData, setCurrentData] = useState('')

    const [company_id, setCompany] = useState('')
    const [branch_id, setBranch] = useState('')
    

    const fetchData = async () => {

        const data = {
            company_id: companyId,
            branch_id: branchId,
            month_id: MonthId
        }

        setLoad(true)
        setCompany(companyId)
        setBranch(branchId)

        try {
            const result = await axios.post('api/dashboard/calendar', data, config)
            if (typeof result.data !== 'undefined') {
                setData(result.data)
                setLoadIndecator(false)
            }
        } catch (error) {
            handleUnAutherize(error)
            console.log('Error')
        }
    }

    const handleDateClick = async (date) => {
        const data = {
            date: formatDate(date),
            company_id: companyId,
            branch_id: branchId,
            month_id: MonthId
        }
        try {
            const result = await axios.post('api/dashboard/sales-order', data, config)
            setSaleOrders(result.data)
        } catch (error) {
            handleUnAutherize(error)
            console.log('Error')
        }
    }

    const getEndDate = () => {
        let date = new Date(),
            date2 = new Date(date.setMonth(date.getMonth()+1))
        return getFirstAndLastDate(date2, true)
    }

    const getStartDate = () => {
        let date = new Date(),
            date2 = new Date(date.setMonth(date.getMonth()-12))
        return getFirstAndLastDate(date2, true)
    }

    const getCurrentCalendarMonth = (title) => {
        let month = title.split(' '), idx = 0
        switch(month[0]) {
            case "มกราคม" : idx = 0; break;
            case "กุมภาพันธ์" : idx = 1; break;
            case "มีนาคม" : idx = 2; break;
            case "เมษายน" : idx = 3; break;
            case "พฤษภาคม" : idx = 4; break;
            case "มิถุนายน" : idx = 5; break;
            case "กรกฎาคม" : idx = 6; break;
            case "สิงหาคม" : idx = 7; break;
            case "กันยายน" : idx = 8; break;
            case "ตุลาคม" : idx = 9; break;
            case "พฤศจิกายน" : idx = 10; break;
            case "ธันวาคม" : idx = 11; break;
            default:break;
        }

        return idx
    }

    const toDayClick = () => {
        $('.fc-today-button').on('click', () => {
            handleDateClick(new Date())
            setCurrentData(new Date())
        })
    }

    useEffect(() => {
        toDayClick()
        if (isLoading === false || (companyId !== company_id || branchId !== branch_id) ) fetchData()
        if((companyId !== company_id || branchId !== branch_id)) {
            setLoadIndecator(true)
        }
    })

    if(loadIndecator) {
        return <Grid item xs={12}>
            <Loading />
        </Grid>
    }

    return <Fragment>
            <Grid item xs={12} sm={8}>
                <FullCalendar
                    defaultView="dayGridMonth"
                    locale="th"
                    buttonText={
                        { today: 'วันนี้', prev: 'เดือนก่อน', next: 'เดือนถัดไป' }
                    }
                    plugins={[dayGridPlugin, interactionPlugin]}
                    ref={ calendarComponentRef }
                    eventClick={(info) => {
                        let event = info.event
                        setCurrentData(event.start)
                        handleDateClick(event.start)
                    }}
                    validRange={ {
                        start: getStartDate(),
                        end: getEndDate()
                    }}
                    weekends={true}
                    events={data}
                    eventTextColor="#ffffff"
                    dateClick={(arg) => {
                        handleDateClick(arg.dateStr)
                        setCurrentData(arg.dateStr)
                    }}
                    eventRender={(event) => {
                        if (formatDate(event.event.start) === formatDate(currentData)) {
                            event.el.style.backgroundColor = "#ff6d88";
                            event.el.style.borderColor = "#ff6d88";
                            event.el.style.color = "white";
                            event.el.style.cursor = "pointer"
                        }
                     }}
                     dayRender={event => {
                        let curMonth = getCurrentCalendarMonth(event.view.title),
                            eventMonth = new Date(event.date).getMonth()
                        if (curMonth !== eventMonth) {
                            event.el.style.backgroundColor = "#ececec";
                            event.el.style.cursor = "pointer"
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SalesOrder data={salesOrder} classes={classes} curDate={currentData}/>
            </Grid>
        </Fragment>
}

