import React, { Fragment, Component } from 'react';
import { Link as RouterLink } from 'react-router-dom'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link'
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { SearchWidget } from 'lib/widgets'
import { createCondition, dataFilter, serializeObj } from 'lib/helper'
import axios from 'axios'
import { config } from 'lib/authFunc'



const root = {
    width: '100%',
    backgroundColor: '#ffffff',
}

const inline = {
    display: 'inline',
}

const fab = {
    position: 'fixed',
    bottom: '10px',
    right: '5px'
}

const ITEM_HEIGHT = 48

export default class MenuMobile extends Component {
    state = {
        items: [],
        search: '',
        perpage: 10,
        delId: null,
        isLoading: false,
        anchorEl: null,
        currentTarget: null
    }

    loadData = async () => {
        if (this.state.isLoading) return
        setTimeout(this.syncData, 2000);
        this.setState({ isLoading: true })
    }

    handleClick = event => {
        this.setState({ 
            anchorEl: event.currentTarget, 
            currentTarget: event.currentTarget.getAttribute('data-id') 
        });
    };

    handleClose = () => {
        this.setState({ anchorEl: null, currentTarget: null });
    }

    syncData = async () => {
        let url = `${this.props.url}?per_page=${this.state.perpage}`
        if(typeof this.props.cond !== 'undefined') {
            let str = serializeObj(this.props.cond)
            url = `${this.props.url}?${str}&per_page=${this.state.perpage}`
        }
        let res = await axios.get(`${url}`, config)
        this.setState({ items: res.data.data, isLoading: false, perpage: this.state.perpage + 20 })
    }

    setSearchData = e => {
        this.setState({ search: e.target.value })
    }

    handleDelete = id => {
        this.setState({ delId: id })
        this.props.onDelete(id)
    }

    removeRowData = () => {
        const index = this.state.items.findIndex(
            data => parseInt(data.id) === parseInt(this.state.delId)
        )
        this.setState({ delId: null })

        if(index > -1) {
           this.state.items.splice(index, 1) 
        }
    }

    actionMenuComponent = (status, id, source_by, empty_stock_balance) => {
        let Btn = ""
        if(this.props.hideAll) return 
        if (typeof this.props.actionLink === 'function') {
            if (window.location.pathname !== '/warehouse/goods-issues') {
                Btn = this.props.actionLink( parseInt(status), id, this.props.changeStatus )
            } else {
                Btn = this.props.actionLink( parseInt(status), id, source_by, empty_stock_balance, this.props.changeStatus )
            }
           
        } 

        if (this.props.deleteOnly) {
            return (
                <IconButton
                    aria-label="More"
                    aria-haspopup="true"
                    onClick={() => this.handleDelete(id)}
                >
                    <DeleteIcon />
                </IconButton>
            )
        }

        return (
            <Fragment>
                <IconButton
                    aria-label="More"
                    aria-haspopup="true"
                    onClick={this.handleClick}
                    data-id={id}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={this.state.anchorEl}
                    open={this.state.currentTarget === id}
                    onClose={this.handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: 200,
                        },
                    }}
                >
                    { Btn !== '' && 
                        <MenuItem style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                            { Btn }
                        </MenuItem>
                    }
                    <MenuItem onClick={() => this.handleDelete(id)} disabled={status !== 0}
                        style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                        ลบข้อมูล
                    </MenuItem>
                </Menu>
            </Fragment>
        )
    }

    searchData = () => {
        if(this.props.withData) {
            let items = dataFilter(this.state.items, this.state.search)
            if(this.state.search === "") {
                items = this.props.data
            }
            
            this.setState({ items: items })
            return
        }

        const { col2, col3, col4 } = this.props.columns
        const cond = '?strict=false&' + createCondition(
            {
                [col2]: '', [col3]: '', [col4]: ''
            },
            this.state.search)
        this.setState({ isLoading: true })
        let url = `${this.props.url}${cond}`
        axios.get(`${url}`, config).then((res) => {
            this.setState({ items: res.data.data, isLoading: false })
        })
    }

    componentDidMount() {
        if(this.props.withData) {
            this.setState({ items: this.props.data })
            return
        }

        window.addEventListener('scroll', this.handleOnScroll);
        this.loadData();
    }

    componentWillUnmount() {
        if(this.props.withData === false) {
            window.removeEventListener('scroll', this.handleOnScroll);
        }
    }

    handleOnScroll = () => {
        var scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        var scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
        var clientHeight = document.documentElement.clientHeight || window.innerHeight;
        var scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

        if (scrolledToBottom) {
            this.loadData()
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.deleteSuccess) {
            this.removeRowData()
            return
        }

        if(this.props.withData) {
            this.setState({ items: this.props.data })
            return
        }
    }

    convertNullValue = val => {
        return val === null ? '' : val
    }

    render() {
        const { col1, col2, col3, col4, col5, col6 } = this.props.columns
        const { title1, title2, title3, title4, title5, title6 } = this.props.title

        return <Fragment>
            <SearchWidget
                onChange={this.setSearchData}
                hideAdvsearch={true}
                onSearch={this.searchData}
            />
            <List style={root}>
                {this.state.isLoading ? <div className="lmask" id="lmask" /> : null}
                {typeof this.state.items === 'object' &&
                    this.state.items.map((data, i) => {
                        return (
                            <Fragment  key={i}>
                                <ListItem alignItems="flex-start">
                                    <Link
                                        component={RouterLink}
                                        to={`${this.props.linkUrl}/${data.id}`}
                                    >
                                        <ListItemText
                                            secondary={
                                                <Fragment>
                                                    <Typography
                                                        component="span"
                                                        variant="body2"
                                                        style={inline}
                                                        color="textPrimary"
                                                    >
                                                       
                                                        { title2(data)}  : { this.convertNullValue(data[col2]) } <br />
                                                        { typeof title1 === 'function' && `${title1(data)} : ${this.convertNullValue(data[col1])}`} { typeof title1 === 'function' && <br /> }
                                                        { typeof title3 === 'function' && `${title3(data)} : ${this.convertNullValue(data[col3])}`}{ typeof title3 === 'function' && <br /> }
                                                        { typeof title4 === 'function' && `${title4(data)} : ${this.convertNullValue(data[col4])}`}{ typeof title4 === 'function' && <br /> }
                                                        { typeof title5 === 'function' && `${title5(data)} : ${this.convertNullValue(data[col5])}`}{ typeof title5 === 'function' && <br /> }
                                                        { typeof title6 === 'function' && `${title6(data)} : ${this.convertNullValue(data[col6])}`}{ typeof title6 === 'function' && <br /> }
                                                    </Typography>
                                                </Fragment>
                                            }
                                        />
                                    </Link>
                                    <ListItemSecondaryAction>
                                        {this.actionMenuComponent(data.status, data.id, data.source_by, data.empty_stock_balance)}
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider component="li" />
                            </Fragment>
                        )
                    })
                }
            </List>
            {this.props.hideCreate === false &&
                <Fab style={fab} color="primary"
                    onClick={() => {
                        window.location = `${this.props.linkUrl}/new`
                    }}
                >
                    <AddIcon />
                </Fab>
            }
        </Fragment>
    }
}

MenuMobile.defaultProps = {
    hideCreate: false,
    deleteOnly: true,
    hideAll: false,
    withData: false
}

