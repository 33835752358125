import React, { Component } from 'react'
import { Formik, FieldArray } from 'formik'
import * as yup from 'yup'
import axios from 'axios'
import { config } from 'lib/authFunc'

import { getIdtoArray } from 'lib/helper'
import { defaultState } from '../defaultState'

import {
  SubmitBtn,
  CheckboxList,
  PageTitle,
  SelectWidget
} from 'lib/widgets'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import { ProductTab } from './ProductTab'
import Divider from '@material-ui/core/Divider'


import { Formstyles } from './AddonStyles'


class ProductForm extends Component {
  _isMounted = false
  state = defaultState

  getCompanies = async () => {
    try {
      const company = axios.get('/api/companies', config)
      const [companies] = await Promise.all([company])
      if (this._isMounted) {
        this.setState({
          companies: companies.data,
          formLoading: false
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  toggleFx = name => {
    let isOpen = this.state[`${name}`]
    this.setState({ [`${name}`]: !isOpen })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.disableForm === false &&
      window.location.pathname !== '/master-data/products/new'
    ) {
      this.disableForm(true)
    }
  }

  componentDidMount() {
    this._isMounted = true
    this.getCompanies()
    if (window.location.pathname !== '/master-data/products/new') {
      this.disableForm(true)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  disableForm = disableForm => {
    this.setState({ disableForm })
  }

  createUnitItem = data => {
    this.props.createUnitItem(data)
  }

  editUnitItem = (id, data) => {
    this.props.editUnitItem(id, data)
  }

  deleteUnitItem = (id, data) => {
    this.props.deleteUnitItem(id, data)
  }

  editPriceBookItem = (id, data) => {
    this.props.editPriceBookItem(id, data)
  }

  render() {

    const { classes, data, onSubmit, isLoading } = this.props
    const { disableForm } = this.state

    data.checkCompanyList =
      typeof data.relations !== 'undefined' &&
      getIdtoArray(data.relations.company)
    data.uomItems =
      typeof data.relations !== 'undefined' ? data.relations.uom : []
    data.pricebookItems =
      typeof data.relations !== 'undefined' ? data.relations.pricebook : []

    return (
      <Grid item xs={12}>
        {isLoading || this.state.formLoading ? <div className="lmask" id="lmask" /> : null}
        <Formik
          enableReinitialize
          initialValues={data}
          validationSchema={yup.object().shape({
            product_category_id: yup.string().required('กรุณากรอก'),
            name: yup.string().required('กรุณากรอก'),
            code: yup.string().required('กรุณากรอก'),
            base_uom_id: yup.string().required('กรุณากรอก'),
            shelf_life_days: yup.string().required('กรุณากรอก')
          })}
          onSubmit={values => onSubmit(values)}
          render={({
            values,
            errors,
            touched,
            resetForm,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
          }) => (
              <Paper className={classes.paper}>
                <form onSubmit={handleSubmit}>
                  <fieldset disabled={disableForm}>
                    <div className={classes.root}>
                      <Grid container spacing={24}>
                        <PageTitle
                          formTitle="สินค้า"
                          action={this.disableForm}
                          edit={disableForm}
                          id={values.id}
                          view="product edit"
                        />
                        <Grid item xs={12} sm={6}></Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            name="code"
                            label="รหัสสินค้า"
                            fullWidth
                            value={values.code || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={disableForm}
                          />
                          {touched.code && errors.code && (
                            <span className="form-text--error">
                              {errors.code}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            name="name"
                            label="ชื่อ"
                            fullWidth
                            value={values.name || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={disableForm}
                          />
                          {touched.name && errors.name && (
                            <span className="form-text--error">
                              {errors.name}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          {errors.checkCompanyList && (
                            <span className="form-text--error">
                              {errors.checkCompanyList}
                            </span>
                          )}
                          <FieldArray
                            name="checkCompanyList"
                            render={arrayHelpers => (
                              <CheckboxList
                                title="บริษัท *"
                                list={this.state.companies}
                                onChange={(e, id) => {
                                  if (e.target.checked) {
                                    if (typeof id === 'object') {
                                      setFieldValue('checkCompanyList', id)
                                    } else {
                                      arrayHelpers.push(id)
                                    }
                                  } else {
                                    if (typeof id === 'object') {
                                      setFieldValue('checkCompanyList', [])
                                    } else {
                                      const idx = values.checkCompanyList.indexOf(
                                        id
                                      )
                                      arrayHelpers.remove(idx)
                                    }
                                  }
                                }}
                                checkList={values.checkCompanyList}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <SelectWidget
                            url="api/master-data/product-categories"
                            value={values.product_category_id || ''}
                            name="product_category_id"
                            title="ประเภทสินค้า *"
                            onChange={setFieldValue}
                            disabled={disableForm}
                          />
                          {touched.product_category_id &&
                            errors.product_category_id && (
                              <span className="form-text--error">
                                {errors.product_category_id}
                              </span>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <SelectWidget
                            url="api/master-data/brands"
                            value={values.brand_id || ''}
                            name="brand_id"
                            title="ยี่ห้อ *"
                            onChange={setFieldValue}
                            disabled={disableForm}
                          />
                          {touched.brand_id && errors.brand_id && (
                            <span className="form-text--error">
                              {errors.brand_id}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <SelectWidget
                            url="api/master-data/uoms"
                            value={values.base_uom_id || ''}
                            name="base_uom_id"
                            title="หน่วยนับเล็กสุด *"
                            onChange={setFieldValue}
                            disabled={disableForm}
                          />
                          {touched.base_uom_id && errors.base_uom_id && (
                            <span className="form-text--error">
                              {errors.base_uom_id}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ paddingTop: '18px' }}>
                          <TextField
                            name="shelf_life_days"
                            label="อายุสินค้า ( วัน )"
                            fullWidth
                            value={values.shelf_life_days || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={disableForm}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="description"
                            label="รายละเอียด"
                            fullWidth
                            value={values.description || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={disableForm}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="secondary"
                                name="active"
                                value="1"
                                onChange={(e) => {
                                  let val = (e.target.checked) ? '1' : '0'
                                  setFieldValue("active", val)
                                }}
                                onBlur={handleBlur}
                                checked={
                                  values.active.toString() === '1'
                                }
                              />
                            }
                            label="Active"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <SubmitBtn
                            disabled={this.state.disableForm}
                            onClear={() => resetForm({})}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </fieldset>
                </form>

              <Divider style={{ marginTop: '20px' }}/>

              { values.id !== '' && 
                <Grid container spacing={24}>
                  <Grid item xs={12}>
                    <ProductTab 
                      rows={values.uomItems}
                      ProductId={values.id}
                      onCreate={this.createUnitItem}
                      onEdit={this.editUnitItem}
                      onDelete={this.deleteUnitItem}
                      rowsPrice={values.pricebookItems}
                      onEditPrice={this.editPriceBookItem}
                      />
                    </Grid>  
                </Grid>
              }

              </Paper>
            )}
        />
      </Grid>
    )
  }
}

export default withStyles(Formstyles)(ProductForm)
