import React, { Component } from 'react'
import MaskedInput from 'react-text-mask'
import PropTypes from 'prop-types'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing.unit
  }
})

function TextMaskCustom(props) {
  const { label, value, onChange, inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[
        '(',
        /[0-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired
}

class PhoneInput extends Component {
  state = {
    value: ''
  }

  handleChange = event => {
    this.setState({ value : event.target.value })
    if (typeof onChange === 'function') {
      this.props.onChange(event.target.value)
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.value })
  }

  render() {
    const { classes, label, value } = this.props

    return (
      <div className={classes.container}>
        <InputLabel htmlFor="formatted-text-mask-input">{label}</InputLabel>
        <Input
          value={this.state.value}
          onChange={this.handleChange}
          inputComponent={TextMaskCustom}
          fullWidth
        />
      </div>
    )
  }
}

// function PhoneInput(props) {
//   const { label, value, onChange } = props;

//   const classes = useStyles();

//   const [values, setValues] = React.useState({
//     textmask: '()    -    ',
//     numberformat: '1320',
//   });

//   const handleChange = name => event => {
//     setValues({ ...values, [name]: event.target.value });
//     if(typeof onChange === 'function') {
//         onChange(event.target.value)
//     }
//   };
// console.log('ss', value)
//   return (
//     <div className={classes.container}>
//         <InputLabel htmlFor="formatted-text-mask-input">{label}</InputLabel>
//         <Input
//           value={value}
//           onChange={handleChange('textmask')}
//           inputComponent={TextMaskCustom}
//           fullWidth
//         />
//     </div>
//   );
// }

export default withStyles(styles)(PhoneInput)
