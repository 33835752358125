import React from 'react'
import Grid from '@material-ui/core/Grid'

import TextField from '@material-ui/core/TextField'
import { SelectWidget, DatePickerWidget } from 'lib/widgets'

const style = {
    marginBottom: '15px'
}
export default ({ values, classes, handleChange, handleSelect, handleDateChange }) => (
  <div style={{ padding: '10px', flexGrow: 1 }}>
      <Grid item xs={12}  style={style}>
      <TextField
        label="ชื่อ"
        name="name"
        value={values.name}
        onChange={handleChange('name')}
        fullWidth
      />
    </Grid>
    <Grid item xs={12}  style={style}>
    <SelectWidget
        url="api/shop-types"
        value={values.shop_type_id}
        name="shop_type_id"
        title="ประเภทร้านค้า"
        onChange={handleSelect('shop_type_id')}
    />
    </Grid>
    <Grid item xs={12} style={style}>
    <SelectWidget
        url="api/master-data/provinces"
        value={values.province_id}
        name="province_id"
        title="จังหวัด"
        onChange={handleSelect('province_id')}
    />
    </Grid>
    <Grid item xs={12} style={style}>
    <SelectWidget
        url="api/master-data/districts"
        value={values.district_id}
        name="district_id"
        title="อำเภอ"
        onChange={handleSelect('district_id')}
    />
    </Grid>
    <Grid item xs={12} style={style}>
    <SelectWidget
        url="api/master-data/users"
        value={values.sale_profile_id}
        name="sale_profile_id"
        title="ชื่อพนักงาน"
        onChange={handleSelect('sale_profile_id')}
    />
    </Grid>
  </div>
)
