import React, { Fragment } from 'react';
import axios from 'axios'
import { config } from 'lib/authFunc'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'

import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'


import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField';

import { VirtualTable } from '@devexpress/dx-react-grid-material-ui'

const loadOptions = async (company_id) => {
    try {
        const warehouse = axios('/api/master-data/warehouses?company_id='+company_id, config)
        const product = axios('/api/master-data/products?company_id='+company_id, config)

        const [warehouses, products] = await Promise.all([warehouse, product])
        let options = [{ 
            warehouse_id : warehouses.data.data,
            product_id : products.data.data
         }]

        return options
    } catch(_) {
        console.log('Error')
    }
}

const checkAvailable = async (product_id, uom_id, warehouse_id, process_type_id, batch_id, bin_id) => {
  let data = {
    product_id : product_id, 
    uom_id: uom_id,
    warehouse_id: warehouse_id,
    process_type_id: process_type_id,
    batch_id: batch_id,
    bin_id: bin_id
  }
  try {
    const check = axios.post('/api/product-checkQty', { data }, config)
    return check
} catch(_) {
    console.log('Error')
}
}

const getRowId = row => row.id;


const AddButton = ({ onExecute, disabledAdd }) => (
  <div style={{ textAlign: 'center' }}>
    <Button
      color="primary"
      onClick={onExecute}
      title="Create new row"
      disabled={disabledAdd}
    >
      New 
    </Button>
  </div>
);

const EditButton = ({ onExecute, disabledEdit }) => (
  <IconButton onClick={onExecute} title="Edit row" disabled={disabledEdit}>
    <EditIcon />
  </IconButton>
);

const DeleteButton = ({ onExecute, disabledDelete }) => (
  <IconButton onClick={onExecute} title="Delete row" disabled={disabledDelete}>
    <DeleteIcon />
  </IconButton>
);

const CommitButton = ({ onExecute, disabledSave }) => (
  <IconButton onClick={onExecute} title="Save changes" disabled={disabledSave}>
    <SaveIcon />
  </IconButton>
);

const CancelButton = ({ onExecute}) => (
  <IconButton color="secondary" onClick={onExecute} title="Cancel changes">
    <CancelIcon />
  </IconButton>
);

const commandComponents = {
  add: AddButton,
  edit: EditButton,
  delete: DeleteButton,
  commit: CommitButton,
  cancel: CancelButton,
};

const RadioWidget = ({prd_option, onValueChange, changeState, value, label}) => (
    <FormControlLabel 
        control={
            <Radio
            checked={prd_option === value}
            onChange={event => {
                onValueChange(event.target.value)
                changeState(event.target.value)
            }}
            value={value}
            name="prd_option"
        />
        }
        label={label}
    />
)

const Cell = props => {
  const { column, row } = props;

  if (column.name === 'product_id') {
    return <TableCell>{ row.product_name }</TableCell>
  }

  if (column.name === 'uom_id') {
    return <TableCell>{ row.uom_name }</TableCell>
  }

  if (column.name === 's_warehouse_id') {
    return <TableCell>{ row.s_warehouse_name }</TableCell>
  }

  if (column.name === 's_bin_id') {
    return <TableCell>{ row.s_storagebin_name }</TableCell>
  }

  if (column.name === 'd_warehouse_id') {
    return <TableCell>{ row.d_warehouse_name }</TableCell>
  }

  if (column.name === 'd_bin_id') {
    return <TableCell>{ row.d_storagebin_name }</TableCell>
  }

  if (column.name === 'batch_id') {
    return <TableCell>{ row.batch_name }</TableCell>
  }

  return <VirtualTable.Cell {...props} />;
}

const tableStyle = {
  margin: '20px auto 20px auto', 
  width: '95%', 
  backgroundColor: '#ffffff',
  borderCollapse: 'collapse'
}

const subTableStyle = {
  margin: '10px auto 10px auto', 
  width: '95%', 
  backgroundColor: '#ffffff',
  borderCollapse: 'collapse'
}

const borderStyle = {
  border: '1px solid #c3c3c3'
}

const subComponent = ({ colSpan, subRow, items, rowMeta, subRow2, handleClickRow }) => {
  return <TableRow>
  <TableCell colSpan={colSpan}>
    <Table style={tableStyle}>
      <TableHead style={{ height: '30px', backgroundColor: 'rgb(188, 205, 239)' }}>
        <TableRow>
          <TableCell style={{ height: '30px',  border: '1px solid #c3c3c3' }}>#</TableCell>
          {subRow.map((data, i) => (
            data.label !== '' &&
            <TableCell key={i} style={{ height: '30px', border: '1px solid #c3c3c3' }}>
              {data.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((row, i) => {
          return <Fragment key={i}>
            <TableRow style={{ height: '30px', cursor: 'pointer' }}
            onClick={() => handleClickRow(`${rowMeta.dataIndex}-subRow-${i}`)}>
              <TableCell style={borderStyle}>{i+1}</TableCell>
              <TableCell style={borderStyle}>{row.storagebin_name}</TableCell>
              <TableCell style={borderStyle}>{row.is_count}</TableCell>
              <TableCell style={borderStyle}>
                {row.created_at}
              </TableCell>
              <TableCell style={borderStyle}>{row.is_accept}</TableCell>
            </TableRow>
            <TableRow id={`${rowMeta.dataIndex}-subRow-${i}`} style={{ display: 'none' }}>
              <TableCell colSpan={colSpan}>
                <Table style={subTableStyle}>
                  <TableHead style={{ height: '30px', backgroundColor: '#d6ecff' }}>
                    <TableRow>
                      <TableCell style={{ height: '30px',  border: '1px solid #c3c3c3' }}>#</TableCell>
                      {subRow2.map((row, i) => (
                        <TableCell key={i} style={borderStyle}>
                          {row.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  { row.detail.map((data, idx) => {
                    return <TableRow  key={idx} style={{ height: '30px', cursor: 'pointer' }}>
                      <TableCell style={borderStyle}>{idx+1}</TableCell>
                      <TableCell style={borderStyle}>{data.product_name}</TableCell>
                      <TableCell style={borderStyle}>
                        {data.batch_name}
                      </TableCell>
                      <TableCell style={borderStyle}>{data.book_qty}</TableCell>
                      <TableCell style={borderStyle}>
                        {data.count_qty}
                      </TableCell>
                      <TableCell style={borderStyle}>
                        {data.uom_name}
                      </TableCell>
                    </TableRow>
                 }) }  
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
          </Fragment>
        })}
      </TableBody>
    </Table>
  </TableCell>
</TableRow>
}

const subCountItem = ({ colSpan, subRow, items }) => {
  return  <TableRow>
  <TableCell colSpan={colSpan}>
    <Table style={subTableStyle}>
      <TableHead style={{ height: '30px', backgroundColor: '#d6ecff' }}>
        <TableRow>
          <TableCell style={{ height: '30px',  border: '1px solid #c3c3c3' }}>#</TableCell>
          {subRow.map((row, i) => (
            <TableCell key={i} style={borderStyle}>
              {row.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
      { items.map((data, idx) => {
        return <TableRow  key={idx} style={{ height: '30px', cursor: 'pointer' }}>
          <TableCell style={borderStyle}>{idx+1}</TableCell>
          <TableCell style={borderStyle}>{data.product_name}</TableCell>
          <TableCell style={borderStyle}>
            <TextField value={data.batch_name} />
          </TableCell>
          <TableCell style={borderStyle}>{data.book_qty}</TableCell>
          <TableCell style={borderStyle}>
            <TextField value={data.count_qty} />
          </TableCell>
          <TableCell style={borderStyle}>
            {data.uom_name}
          </TableCell>
          <TableCell style={borderStyle}>
            <EditButton />
          </TableCell>

        </TableRow>
     }) }  
      </TableBody>
    </Table>
  </TableCell>
</TableRow>
}
export {
    loadOptions,
    getRowId,
    commandComponents,
    CancelButton,
    CommitButton,
    DeleteButton,
    EditButton,
    AddButton,
    RadioWidget,
    Cell,
    subComponent,
    subCountItem,
    checkAvailable
}