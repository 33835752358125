import React, { useState, useEffect, Fragment } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import LooksOne from '@material-ui/icons/LooksOne';
import LooksTwo from '@material-ui/icons/LooksTwo';
import Looks3 from '@material-ui/icons/Looks3';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';


import { getCurrentYear, monthList, numberFormat } from 'lib/helper'
import axios from 'axios'
import { config } from 'lib/authFunc'

import { Loading } from 'lib/widgets'

const paper = {
    border: '1.5px solid #ffc7e3',
    borderRadius: '5px',
    padding: '10px',
    height: '500px'
}

export default ({ classes, companyId, branchId, MonthId, forMat }) => {

    const [month_id, setMonth] = useState('')
    const [data, setData] = useState([])
    const [loadIndecator, setLoadIndecator] = useState(true)
    const [company_id, setCompany] = useState('')
    const [branch_id, setBranch] = useState('')
    const [format_id, setFormat] = useState('')

    const fetchData = async () => {

        const data = {
            company_id: companyId,
            branch_id: branchId,
            month_id: MonthId,
            format_id: forMat
        }

        setMonth(MonthId)
        setCompany(companyId)
        setBranch(branchId)
        setFormat(forMat)

        const result = await axios.post('api/dashboard/top-customer', data, config)
        if (typeof result.data !== 'undefined') {
            setData(result.data)
            setLoadIndecator(false)
        }
    }

    const openDetail = () => {
        const params = `company_id=${company_id}&branch_id=${branch_id}&month_id=${month_id}&format_id=${format_id}`
        window.open(`/dashboard/details/customer?${params}`)
    }

    const getPageTitle = () => {
        let title = ""
        switch (format_id) {
            case '1': title = 'ประจำสัปดาห์'; break;
            case '2': title = `ประจำเดือน ${monthList(parseInt(month_id))} ${getCurrentYear()}`; break;
            case '3': title = `ประจำปี ${getCurrentYear()}`; break;
            default: break;
        }

        return title
    }

    useEffect(() => {
        if (month_id !== MonthId || (companyId !== company_id || branchId !== branch_id || forMat !== format_id)) fetchData()
        if ((companyId !== company_id || branchId !== branch_id)) {
            setLoadIndecator(true)
        }
    })

    const DividerComponent = () => (
        <Grid item xs={12}>
            <Divider />
        </Grid>
    )

    if (loadIndecator) {
        return <Grid item xs={12}>
            <Loading />
        </Grid>
    }

    return <Fragment>
        <div style={paper}>
            <Grid container justify="center" spacing={16}>

                <Grid item xs={12}>
                    <Typography variant="h5" component="h3" style={{ color: '#f5007c' }}>
                        Top Customer
                    </Typography>
                    <Typography component="p">
                        {getPageTitle()}
                    </Typography>
                </Grid>

                <Grid item xs={12} >
                    <Avatar className={classes.avatar} style={{ backgroundColor: '#f5007c' }}>
                        <LooksOne />
                    </Avatar>
                    <Typography component="h6" variant="h6" style={{ textAlign: 'center', fontSize: '15px' }}>
                        คุณ {typeof data[0] === 'object' ? data[0].name : ''}
                    </Typography>
                    <Typography component="p" style={{ textAlign: 'center' }}>
                        ยอด {typeof data[0] === 'object' ? numberFormat(data[0].net_amt) : 0.00} บาท
                    </Typography>
                </Grid>

                <DividerComponent />


                <Grid item xs={2} sm={2}>
                    <LooksTwo />
                </Grid>
                <Grid item xs={10} sm={10}>
                    <Typography component="p">
                        คุณ {typeof data[1] === 'object' ? data[1].name : ''}
                    </Typography>
                    <Typography>
                        ยอด {typeof data[1] === 'object' ? numberFormat(data[1].net_amt) : 0.00} บาท
                        </Typography>
                </Grid>


                <DividerComponent />


                <Grid item xs={2} sm={2}>
                    <Looks3 />
                </Grid>
                <Grid item xs={10} sm={10}>
                    <Typography component="p">
                        คุณ {typeof data[2] === 'object' ? data[2].name : ''}
                    </Typography>
                    <Typography>
                        ยอด {typeof data[2] === 'object' ? numberFormat(data[2].net_amt) : 0.00} บาท
                    </Typography>
                </Grid>


                <DividerComponent />

                <Grid item xs={12}>
                    <Button fullWidth
                        style={{ backgroundColor: '#f16fb0', color: 'white' }}
                        onClick={() => openDetail()}>
                        ดูข้อมูลทั้งหมด
                    </Button>
                </Grid>

            </Grid>
        </div>
    </Fragment>
}