import axios from 'axios'
import { headerWithAuth } from 'lib/authFunc'
import { getFirstAndLastDate } from 'lib/helper'

const  header = headerWithAuth()

const styles = theme => ({
    appBar: {
      position: 'relative',
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      padding: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
        marginTop: theme.spacing.unit * 6,
        marginBottom: theme.spacing.unit * 6,
        padding: theme.spacing.unit * 3,
      },
    },
    stepper: {
      padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing.unit * 3,
      marginLeft: theme.spacing.unit,
    },
    formControl: {
      margin: theme.spacing.unit,
      minWidth: '100%',
    },
    formControlRadio: {
      margin: theme.spacing.unit * 3,
    },
    group: {
      margin: `${theme.spacing.unit}px 0`,
    },
  });

  const loadOptions = async () => {
    try {
        const uomUrl = axios('/api/companies', header)
        const categoryUrl = axios('/api/master-data/product-categories', header)
        const brandUrl = axios('/api/master-data/brands', header)
        const productUrl = axios('/api/master-data/products', header)
    
        const [ company, category, brand, product ] = await Promise.all([uomUrl, categoryUrl, brandUrl, productUrl])
        let options = [{ 
            companies : company.data.data,
            categories : category.data.data,
            brands: brand.data.data,
            products: product.data.data
         }]

        return options
    } catch(_) {
        console.log('Error')
    }
}

const defaultState = {
  company_id: '',
  branch_id: '',
  brand_id: 'all',
  product_category_id: 'all',
  brand_id_front: [],
  product_id_front: [],
  product_id: 'all',
  pd_option: 'pd_name',
  pd_option_label: 'ชื่อสินค้า',
  date_start: getFirstAndLastDate(null, true, 'd-m-Y'),
  date_end: getFirstAndLastDate(null, false, 'd-m-Y'),
  companies: [],
  branches: [],
  category: [],
  productname: [],
  productcode: [],
  brand: [],
  bkcate: [],
  bkcompany: [],
  productbk: [],
  isLoading: false,
  loadopt: true
}


  export {
    styles,
    loadOptions,
    defaultState
  }