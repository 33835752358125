import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { headerWithAuth, handleUnAutherize } from 'lib/authFunc'
import * as actions from './actions'
import { push } from 'connected-react-router'

const  header = headerWithAuth()

function* loadStores(action) {
  try {
    const { term } = action.payload
    const res = yield call(
      axios.get,
      `api/warehouses${term}`,
      header
    )
    yield put(actions.loadStores.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadStores.failure(error.response))
  }
}

function* loadStore(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.get, `/api/warehouses/${id}`, header)
    yield put(actions.loadStore.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadStore.failure(error.response))
  }
}

function* createStore(action) {
  try {
    const { store } = action.payload
    const res = yield call(axios.post, '/api/warehouses', { store }, header)
    yield put(push(`/master-data/stores/${res.data.id}`))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.createStore.failure(error))
  }
}

function* editStore(action) {
  try {
    const { store, id } = action.payload
    const res = yield call(axios.patch, `/api/warehouses/${id}`, store, header)

    yield put(actions.editStore.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.editStore.failure(error.response))
  }
}

function* deleteStore(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.delete, `/api/warehouses/${id}`, header)
    yield put(actions.deleteStore.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.deleteStore.failure(error.response))
  }
}

function* createStorageBin(action) {
  try {
    const { bin } = action.payload
    const res = yield call(axios.post, '/api/bins', { bin }, header)
    yield put(actions.createStorageBin.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.createStorageBin.failure(error))
  }
}

function* editStorageBin(action) {
  try {
    const { bin, id } = action.payload
    const res = yield call(axios.patch, `/api/bins/${id}`, bin, header)

    yield put(actions.editStorageBin.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.editStorageBin.failure(error.response))
  }
}

function* deleteStorageBin(action) {
  try {
    const { id, bin } = action.payload
    const res = yield call(axios.patch, `/api/bins/delete/${id}`, bin, header)
    yield put(actions.deleteStorageBin.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.deleteStorageBin.failure(error.response))
  }
}


export default function* watchComments() {
  yield all([
    takeEvery(actions.LOAD_STORES.REQUEST, loadStores),
    takeEvery(actions.LOAD_STORE.REQUEST, loadStore),
    takeEvery(actions.CREATE_STORE.REQUEST, createStore),
    takeEvery(actions.EDIT_STORE.REQUEST, editStore),
    takeEvery(actions.DELETE_STORE.REQUEST, deleteStore),
    takeEvery(actions.CREATE_STORE_BIN.REQUEST, createStorageBin),
    takeEvery(actions.EDIT_STORE_BIN.REQUEST, editStorageBin),
    takeEvery(actions.DELETE_STORE_BIN.REQUEST, deleteStorageBin),
  ])
}
