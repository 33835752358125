const COMPANY_ID = 1
const MAIN_BRANCH = 1
const COMPANY_NAME = "Singhachan"
const PUSHER_KEY = "1b41c4c73d28bca61846"
const API_URL = "https://singhachan-intelsales.bisimosfa.com:8443 "
const FORM_URL = "http://singhachan-intelsales.bisimosfa.com:8082"
export {
    API_URL,
    COMPANY_ID,
    MAIN_BRANCH,
    COMPANY_NAME,
    PUSHER_KEY,
    FORM_URL

}
