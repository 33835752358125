import React, { Component, Fragment } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'

import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

import { DatePickerWidget, MultipleSelect, SelectWidget, Breadcrumb } from 'lib/widgets'
import { styles, loadOptions, defaultState } from './RelateFunc'
import { genOptionList, genRelateOptionList, formatDate, getIdtoArray } from 'lib/helper'

import axios from 'axios'
import { headerWithAuth } from 'lib/authFunc'


class ProductApp extends Component {
  constructor(props) {
    super(props)
    this._isMounted = false
    this.state = defaultState
  }
  

  handleExport = async values => {
    this.setState({ isLoading: true })
    try {
      const response = await axios.post(
        '/api/report/products',
        values,
        headerWithAuth()
      )
      const link = document.createElement('a')
      link.href = response.data
      let fileName = `product_report.xlsx`
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      this.setState({ isLoading: false })
      console.log(response)
    } catch (error) {
      console.error(error)
    }
  }

  handleChange = (name, data) => {
    this.setState({ [name]: data }, () => this.setRelateOption(name))
  }

  getProductList = (items, value) => {
    let list = []
    items.map((data, i) => {
      if(value.includes(data.name)) {
        data.idx = i
        list.push(data)
      }
    })


    let products = []
    list.map(data => {
      products = [...products, ...data.products]
    })

    return products
  }

  setRelateOption = name => {
    let items = name === 'company_id' ? this.state.bkcompany : this.state.bkcate
    let idx = items.findIndex(
      data => parseInt(data.id) === parseInt(this.state[name])
    )

    if (name === 'product_category_id') {
      let product = this.getProductList(this.state.bkcate, this.state[name])
      this.setState({
        productname: genOptionList(product, 'id', 'name'),
        productcode: genOptionList(product, 'id', 'code')
      })
    }

    if (idx < 0) return

    if (name === 'company_id') {
      this.setState({
        branches: genRelateOptionList(
          [items[idx]],
          'id',
          'name',
          'branch',
          'company_id'
        )
      })
    }
  }

  componentDidMount() {
    this._isMounted = true
    loadOptions().then(res => {
      if(this._isMounted) {
          this.setState({
            companies: genOptionList(res[0].companies, 'id', 'name'),
            category: genOptionList(res[0].categories, 'id', 'name'),
            brand: genOptionList(res[0].brands, 'id', 'name'),
            bkcate: res[0].categories,
            bkcompany: res[0].companies,
            productbk: res[0].products,
            loadopt: false
          })
      }
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { classes } = this.props
    const {
      companies,
      branches,
      category,
      productname,
      productcode,
      brand,
      isLoading,
      pd_option_label,
      loadopt
    } = this.state

    return (
      <Grid container spacing={24}>
        <Grid item xs={12} style={{ marginTop: '20px', marginLeft: '20px' }}>
          <Breadcrumb
            menu="รายงาน"
            submenu="รายงานยอดขายแยกตามสินค้า"
            menuLink="/report/products"
          />
        </Grid>
        <Grid item xs={12}>
          <Formik
            initialValues={defaultState}
            validationSchema={yup.object().shape({
              company_id: yup.string().required('กรุณากรอก'),
              branch_id: yup.string().required('กรุณากรอก'),
              product_category_id:  yup.array()
                                    .nullable()
                                    .required('กรุณากรอก'),
              product_id_front:  yup.array()
                                    .nullable()
                                    .required('กรุณากรอก'), 
              brand_id_front:  yup.array()
                                    .nullable()
                                    .required('กรุณากรอก'),                
              date_start: yup.string().required('กรุณากรอก'),
              date_end: yup.string().required('กรุณากรอก')
            })}
            onSubmit={values => this.handleExport(values)}
            render={({
              values,
              errors,
              touched,
              resetForm,
              handleSubmit,
              setFieldValue
            }) => {
              return <Fragment>
                { isLoading &&  <div className="lmask" id="lmask" />}
                <form className={classes.layout}>
                  <Paper className={classes.paper}>
                    <Typography variant="h6" gutterBottom>
                      รายงานยอดขายแยกตามสินค้า
                    </Typography>
                    <Grid container spacing={24}>
                      <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl}>
                            <SelectWidget
                                withOption={true}
                                options={companies}
                                value={values.company_id || ''}
                                name="company_id"
                                title="บริษัท*"
                                disabled={this.state.disableForm}
                                onChange={(name, value) => {
                                  setFieldValue(name, value)
                                  this.handleChange('company_id', value)
                                }}
                                isLoading={loadopt}
                              />
                          {touched.company_id && errors.company_id && (
                            <span className="form-text--error">
                              {errors.company_id}
                            </span>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}  sm={6}>
                        <FormControl className={classes.formControl}>
                            <SelectWidget
                                withOption={true}
                                options={branches}
                                value={values.branch_id || ''}
                                name="branch_id"
                                title="สาขา*"
                                onChange={setFieldValue}
                                disabled={loadopt}
                              />
                          {touched.branch_id && errors.branch_id && (
                            <span className="form-text--error">
                              {errors.branch_id}
                            </span>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '0px', paddingTop: '0px' }}>
                        <FormControl className={classes.formControl} style={{ maxWidth: '80%' }}>
                          <InputLabel>ประเภทสินค้า *</InputLabel>
                          <MultipleSelect 
                            name="product_category_id"
                            value={values.product_category_id} 
                            options={category}
                            onChange={ (name, value) => {
                              setFieldValue(name, value)
                              this.handleChange('product_category_id', value)
                            }}/>
                          {touched.product_category_id &&
                            errors.product_category_id && (
                              <span className="form-text--error">
                                {errors.product_category_id}
                              </span>
                            )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '0px', paddingTop: '0px' }}>
                        <FormControl className={classes.formControl} style={{ maxWidth: '80%' }}>
                          <InputLabel>ยี่ห้อ</InputLabel>
                          <MultipleSelect 
                            name="brand_id_front"
                            value={values.brand_id_front} 
                            options={brand}
                            onChange={ (name, value) => {
                              setFieldValue(name, value)
                              let filter = brand.filter(x => value.includes(x.label))
                              if (filter.length === brand.length) {
                                setFieldValue('brand_id', 'all')
                              } else {
                                setFieldValue('brand_id', getIdtoArray(filter, 'value'))
                              }
                              
                              this.handleChange('brand_id', value)
                            }}/>
                          {touched.brand_id_front && errors.brand_id_front && (
                            <span className="form-text--error">
                              {errors.brand_id_front}
                            </span>
                          )}
                            {/* <SelectWidget
                                withOption={true}
                                options={brand}
                                value={values.brand_id || ''}
                                name="brand_id"
                                title="ยี่ห้อ"
                                disabled={this.state.disableForm}
                                onChange={(name, value) => {
                                  setFieldValue(name, value)
                                }}
                                isLoading={loadopt}
                              /> */}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '0px', paddingTop: '0px' }}>
                        <FormControl className={classes.formControl} style={{ padding: '0px', paddingTop: '0px' }}>
                          <RadioGroup
                            style={{ display: 'block' }}
                            name="pd_option"
                            className={classes.group}
                            value={values.pd_option}
                            onChange={e => {
                              setFieldValue('pd_option', e.target.value)
                              let label =
                                e.target.value === 'pd_code'
                                  ? 'รหัสสินค้า'
                                  : 'ชื่อสินค้า'
                              this.setState({ pd_option_label: label })
                            }}
                          >
                            <FormControlLabel
                              value="pd_code"
                              control={<Radio />}
                              label="รหัสสินค้า"
                            />
                            <FormControlLabel
                              value="pd_name"
                              control={<Radio />}
                              label="ชื่อสินค้า"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={{ paddingTop: '0px' }}>
                        <FormControl className={classes.formControl}>
                          <InputLabel>ชื่อสินค้า</InputLabel> 
                           <MultipleSelect 
                            name="product_id_front"
                            value={values.product_id_front} 
                            options={
                              values.pd_option === 'pd_name'
                              ? productname
                              : productcode
                            }
                            onChange={ (name, value) => {
                              setFieldValue(name, value)
                              let opt = values.pd_option === 'pd_name'
                              ? productname
                              : productcode

                              let filter = opt.filter(x => value.includes(x.label))

                              if (filter.length === opt.length) {
                                setFieldValue('product_id', 'all')
                              } else {
                                setFieldValue('product_id', getIdtoArray(filter, 'value'))
                              }

                              this.handleChange('product_id', value)
                            }}/>
                          {touched.product_id_front && errors.product_id_front && (
                            <span className="form-text--error">
                              {errors.product_id_front}
                            </span>
                          )}
                            {/* <SelectWidget
                                withOption={true}
                                options={
                                  values.pd_option === 'pd_name'
                                  ? productname
                                  : productcode
                                }
                                value={values.product_id || ''}
                                name="product_id"
                                title={pd_option_label}
                                disabled={this.state.disableForm}
                                onChange={(name, value) => {
                                  setFieldValue(name, value)
                                }}
                                isLoading={loadopt}
                              /> */}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl}>
                          <DatePickerWidget
                            name="date_start"
                            title="วันเริ่มต้น *"
                            value={values.date_start || ''}
                            format="dd-MM-yyyy"
                            onValueChange={(data, name) => {
                              setFieldValue(name, formatDate(data, 'd-m-Y'))
                            }}
                          />
                          {touched.date_start && errors.date_start && (
                            <span className="form-text--error">
                              {errors.date_start}
                            </span>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl}>
                          <DatePickerWidget
                            name="date_end"
                            title="วันสิ้นสุด *"
                            value={values.date_end || ''}
                            format="dd-MM-yyyy"
                            onValueChange={(data, name) => {
                              setFieldValue(name, formatDate(data, 'd-m-Y'))
                            }}
                          />
                          {touched.date_end && errors.date_end && (
                            <span className="form-text--error">
                              {errors.date_end}
                            </span>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <div className={classes.buttons}>
                          <Button
                            variant="contained"
                            color="default"
                            onClick={() => resetForm(defaultState)}
                            className={classes.button}
                          >
                            เคลียร์
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={handleSubmit}
                            className={classes.button}
                          >
                            ตกลง
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                </form>
              </Fragment>
            }}
          />
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(ProductApp)
