import * as React from 'react';
import { EditingState } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table, TableHeaderRow, TableEditRow, TableEditColumn, TableColumnVisibility
} from '@devexpress/dx-react-grid-material-ui';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { genOptionList } from 'lib/helper'
import { LookupEditCell } from 'lib/widgets'
import axios from 'axios'

import { config } from 'lib/authFunc'
import { hasMessage } from 'lib/fxUI'

const styles = theme => ({
  lookupEditCell: {
    paddingTop: theme.spacing.unit * 0.875,
    paddingRight: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
  },
  dialog: {
    width: 'calc(100% - 16px)',
  },
  inputRoot: {
    width: '100%',
  },
});

const EditButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Edit row">
    <EditIcon />
  </IconButton>
);


const CommitButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Save changes">
    <SaveIcon />
  </IconButton>
);

const CancelButton = ({ onExecute }) => (
  <IconButton color="secondary" onClick={onExecute} title="Cancel changes">
    <CancelIcon />
  </IconButton>
);

const editingStateColumnExtensions = [
  { columnName: 'product_name', editingEnabled: false },
  { columnName: 'book_qty', editingEnabled: false },
  { columnName: 'uom_name', editingEnabled: false },
]

const commandComponents = {
  edit: EditButton,
  commit: CommitButton,
  cancel: CancelButton,
};

const Command = ({ id, onExecute }) => {
  const CommandButton = commandComponents[id];
  return (
    <CommandButton
      onExecute={onExecute}
    />
  );
};

const changeRowId = (rows) => {
  return rows.map((data,i) => {
    data.lineNo = i
    return data
  })
}


class SubTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this._manualBatch = ""
    this.state = {
      columns: [
        { title:"", name: 'product_id' },
        { title:"", name: 'id' },
        { title: "สินค้า", name: 'product_name' }, 
        { title: "Batch", name: 'batch_name' }, 
        { title:"Book Qty", name: 'book_qty' }, 
        { title:"Count Qty", name: 'count_qty'},
        { title:"หน่วยนับ", name: 'uom_name' }
      ],
      rows: [],
      editingRowIds: [],
      rowChanges: {},
      isLoading: true,
      saveSuccess: true
    }

    this.handleRow = (data, checked) => {
      const { rows } = this.state
      rows[data.rowId].other_batch = checked
      
      this.setState({ rows })
    }

    this.EditCell = (props) => {
      const { column, tableRow, onValueChange, value } = props
      const other_batch = this.state.rows[tableRow.rowId].other_batch
      if (column.name === 'batch_name') {
        if(other_batch === 1) {
          return <TableCell>
            <TextField
              value={value}
              fullWidth
              onChange={(e) => onValueChange(e.target.value)}
            />
          <FormControlLabel control={
          <Checkbox  
              value="1"
              name="other_batch"
              checked={other_batch === 1}
              onChange={(e) => {
                this.handleRow(tableRow, e.target.checked === true ? 1 : 0)
                onValueChange(e.target.checked === true ? 1 : 0)
              }}
              color="primary" />} 
              label="Batch อื่นๆที่ไม่อยู่ในรายการ" 
            style={{ marginLeft: '0px' }}
          />
      </TableCell>
        } else {
          return <TableCell>
                    <LookupEditCell {...props} 
                      availableColumnValues={genOptionList(tableRow.row.list, 'id', 'name')} 
                      withTableCell={false}
                    />
                    <FormControlLabel control={
                    <Checkbox  
                        value="1"
                        name="other_batch"
                        checked={other_batch === 1}
                        onChange={(e) => {
                          this.handleRow(tableRow, e.target.checked === true ? 1 : 0)
                          onValueChange(e.target.checked === true ? 1 : 0)
                        }}
                        color="primary" />} 
                        label="Batch อื่นๆที่ไม่อยู่ในรายการ" 
                      style={{ marginLeft: '0px' }}
                    />
                </TableCell>
        }
      }

      return <TableEditRow.Cell {...props} />
    }

    this.changeEditingRowIds = editingRowIds => this.setState({ editingRowIds });
    this.changeRowChanges = rowChanges => this.setState({ rowChanges });
    this.commitChanges = ({ added, changed, deleted }) => {
      let { rows } = this.state;

      if (changed) {
        let data = []
        rows = rows.map(row => {
            if(changed[row.lineNo]) data = { ...row,  ...changed[row.lineNo]}
            return changed[row.lineNo] ? { ...row, ...changed[row.lineNo], ...{'batch_name' : row.batch_name} } : row
        });
        this.updateDetail(data)
      }

      this.setState({ rows });
    }
  }

  updateDetail = async (data) => {
    const res = axios.post('/api/physical-counts-updatedetail', data, config)
    res.then(data => {
        if(data.status !== 200) 
          this.setState({ saveSuccess: false})
    })
  }

  componentDidMount() {
    this.setState({ rows : changeRowId(this.props.items) })
  }

  render() {
    const {
      rows,
      columns,
      editingRowIds,
      rowChanges,
    } = this.state;

    return (
      <Paper>
        { this.state.successSave === false && hasMessage(false, '') }
        <Grid
          rows={rows}
          columns={columns}
        >
          <EditingState
            editingRowIds={editingRowIds}
            onEditingRowIdsChange={this.changeEditingRowIds}
            rowChanges={rowChanges}
            onRowChangesChange={this.changeRowChanges}
            onCommitChanges={this.commitChanges}
            columnExtensions={editingStateColumnExtensions}
          />

          <Table />
          <TableHeaderRow />
          <TableColumnVisibility
            hiddenColumnNames={['product_id', 'id']}
          />
          <TableEditRow
            cellComponent={this.EditCell}
          />
          <TableEditColumn
            width={170}
            showEditCommand
            commandComponent={Command}
          />

        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles, { name: 'ControlledModeDemo' })(SubTable);
