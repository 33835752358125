const DRAFT = 0,
  CONFIRM = 1,
  PARTIAL = 2,
  CLOSE = 3,
  CANCEL = 4,
  IMPORT = 5,
  ISSUE = 6

const getGRNextStatus = status => {
  let newStatus = ''
  switch (status) {
    case DRAFT:
      newStatus = CONFIRM
      break
    case CONFIRM:
      newStatus = CANCEL
      break
    case PARTIAL:
      newStatus = CLOSE
      break
    default:
      break
  }

  return newStatus
}

const getGTNextStatus = status => {
  let newStatus = ''
  switch (status) {
    case DRAFT:
      newStatus = CONFIRM
      break
    case CONFIRM:
      newStatus = CANCEL
      break
    case PARTIAL:
      newStatus = CLOSE
      break
    default:
      break
  }

  return newStatus
}

const getGINextStatus = (status, source_by, isEmptyStock) => {
  let newStatus = ''
  switch (status) {
    case DRAFT:
      newStatus = CONFIRM
      break
    case CONFIRM:
      newStatus = CANCEL
      if (source_by === 'SFA') {
        newStatus = isEmptyStock === 0 ? ISSUE : CANCEL
      }
      break
    case PARTIAL:
      newStatus = CLOSE
      break
    default:
      break
  }

  return newStatus
}

export { getGRNextStatus, getGTNextStatus, getGINextStatus }
