import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { trackPromise } from 'react-promise-tracker';
import ComponentList from './ComponentList'
import axios from 'axios'
import { config, handleUnAutherize } from 'lib/authFunc'
//import { pusherInstance } from 'lib/pusherInstance'
import Pusher from 'pusher-js'
import { PUSHER_KEY } from 'lib/defaultSystemData'


const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    background: '#ffffff'
  }
})

function AllNotiList(props) {
  const { classes, loadingProgress } = props
  const [items, setItems] = useState([])
  const [isLoad, setLoad] = useState(false)

  var pusher = null

  const loadList = () => {
    trackPromise(
      axios.get(`/api/noti`, config).then(data => {
        if (data.status === 401 || data.status === 500) {
          handleUnAutherize(data)
        }
        setItems(data.data.data)
        setLoad(true)
      })
    )
  }

  const handleDelete = (id) => {
    let res = axios.delete(`/api/noti/${id}`, config)
    res.then(data => {
      if (data.status === 200) loadList()
    })
  }

  const handleUpdate = (id, status) => {
    let res = axios.put(`/api/noti/${id}`, { status: status }, config)
    res.then(data => {
      if (data.status === 200) loadList()
    })
  }

  const notify = () => {
    pusher = new Pusher(PUSHER_KEY, {
      cluster: 'ap1',
      encrypted: false
    })
    var channel = pusher.subscribe('message-notify');
    channel.bind(`App\\Events\\MessageNotify`, function (data) {
      setLoad(false)
    })
  }

  useEffect(() => {
    notify()
    if (isLoad === false) loadList()
    return async () => {
      pusher.disconnect()
    }
  })

  return (
    <Paper className={classes.root} elevation={1}>
      <Grid container spacing={16}>
        <Grid item xs>
          <Grid container justify="space-between">
            <Grid item xs={4}>
              <Typography variant="h6" gutterBottom>
                การแจ้งเตือนทั้งหมด
          </Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
                <Link component="button" onClick={() => handleDelete('all')}>
                  ลบทั้งหมด
                </Link>
                &nbsp;&nbsp;
                <Link component="button" onClick={() => handleUpdate('all', 1)}>
                  อ่านทั้งหมด
                </Link>
            </Grid>
          </Grid>

          <List  >
            <ComponentList
              items={items}
              loadingProgress={loadingProgress}
              onDelete={handleDelete}
              onUpdate={handleUpdate} />
          </List>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default withStyles(styles)(AllNotiList);