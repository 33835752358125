import React from 'react';
import { EditingState } from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn
} from '@devexpress/dx-react-grid-material-ui';

import TableCell from '@material-ui/core/TableCell';

import { genOptionList, genRelateOptionList, formatDate, displayOptionLabel } from 'lib/helper'
import { defaultState, tableColumnExtensions } from '../defaultState'
import { DatePickerWidget, Number } from 'lib/widgets'

import { 
  loadOptions,
  commandComponents,
  checkAvailable,
  getRowId,
  Cell
} from './RelateFunc'

import { LookupEditCell } from 'lib/widgets'
import { checkAction } from 'lib/authFunc'

export class DocItems extends React.PureComponent {
  constructor(props) {
    super(props);
    this._prdId = 0
    this._warehouseId = 0
    this._isMounted = false
    this.state = {
      rows: defaultState.relations.detail,
      currentUrl: '',
      company_id: '',
      columns:defaultState.columns,
      prd_option: 'name',
      uom_id: [],
      warehouse_id: [],
      product_id:[],
      bin_id:[],
      batch_id:[],
      processType: '',
      editingStateColumnExtensions: [
        { columnName: 'batch_id', editingEnabled: false },
        { columnName: 'bin_id', editingEnabled: false },
      ],
      disableSave: true,
      disabledEdit: false,
      isLoading: true,
      textQty : '',
      onhand_qty: 0,
      c_prd: '',
      c_uom: '',
      c_batch: '',
      c_warehouse: '',
      c_bin: '',
      curIdx: '',
      editingRowIds: ''
    }
  }

  changeProductOptions = value => {
     this.setState({ ...this.state, prd_option: value })
  }

  editCell = props => {
    const { column, onValueChange, value } = props
    const { prd_option, product_name_list, product_code } = this.state
    const dateInputName = ['manufacturing_date', 'expired_date']

    let selectName = ['warehouse_id', 'product_id']
    let relateSelectName = ['uom_id']

    if(this.props.processType.toString() === '5') {
      selectName = ['warehouse_id', 'product_id']
      relateSelectName = ['bin_id', 'uom_id', 'batch_id']
    }
    

    if(selectName.includes(column.name)) {
        let availableColumnValues = this.state[column.name];

        if(column.name === 'product_id') {
          availableColumnValues = (prd_option === 'name') ? product_name_list : product_code
        }

        if (availableColumnValues) {
          if(column.name === 'product_id') {
            this._prdId = value
            return (
              <TableCell>
                <LookupEditCell {...props} 
                  availableColumnValues={availableColumnValues} 
                  withTableCell={false}
                  />
                </TableCell>
            )
          }
          this._warehouseId = value
          return <LookupEditCell {...props} availableColumnValues={availableColumnValues} />
        }
    }

    if(relateSelectName.includes(column.name)) {

        var availableColumnValues = this.state.uom_id.filter((data) => {
            return parseInt(data.fk) === parseInt(this._prdId)
        })

        if(column.name === 'batch_id') {
          availableColumnValues = this.state.batch_id.filter((data) => {
              return parseInt(data.fk) === parseInt(this._prdId)
          })
        }
 
        if(column.name === 'bin_id') {
            availableColumnValues = this.state.bin_id.filter((data) => {
              return parseInt(data.fk) === parseInt(this._warehouseId)
          })
        } 

        if (availableColumnValues) {
          return <LookupEditCell {...props} availableColumnValues={availableColumnValues} />;
        }
    }

    if(dateInputName.includes(column.name)) {
      return <TableCell>
       <DatePickerWidget 
         name={column.name}
         value={value} 
         onValueChange={value => {
          let date = value === '' ? value : formatDate(value)
          onValueChange(date, column.name)
         }}/>
     </TableCell>
    }

    if(column.name === 'order_qty') {
      return <TableCell>
      <Number
        value={value}
          onChange={value => {
            onValueChange(value, 'order_qty')
          }
        }
      />
        <span className="form-text--error">{this.state.textQty}</span>
      </TableCell>
    }

    return <TableEditRow.Cell {...props} />;
  }

  command = ({ id, onExecute, ...restProps }, disableSave, disabledEdit) => {
    const CommandButton = commandComponents[id];
    return (
      <CommandButton
        onExecute={onExecute}
        disabledSave={disableSave}
        disabledEdit={disabledEdit}
        disabledDelete={disabledEdit}
        disabledAdd={disabledEdit}
      />
    );
  }

  commitChanges = ({ added, changed, deleted }) => {
    let { rows, uom_id, product_name_list, warehouse_id, bin_id } = this.state;
    if (added) {
      const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
      rows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          line_no: startingAddedId + index,
          gi_order_id: this.props.docId,
          ...row,
        })),
      ];
      this.props.onCreate(rows[rows.length - 1])
      const currentItem = rows[rows.length - 1]
      rows[rows.length - 1].uom_name = displayOptionLabel(uom_id, currentItem, 'uom_id')
      rows[rows.length - 1].product_name = displayOptionLabel(product_name_list, currentItem, 'product_id') 
      rows[rows.length - 1].warehouse_name = displayOptionLabel(warehouse_id, currentItem, 'warehouse_id')
      rows[rows.length - 1].storagebin_name = displayOptionLabel(bin_id, currentItem, 'bin_id') 
    }
    if (changed) {
      rows = rows.map(row => {
        if(changed[row.id]) {
          if(typeof changed[row.id].uom_id !== 'undefined') changed[row.id].uom_name = displayOptionLabel(uom_id, changed[row.id], 'uom_id')
          if(typeof changed[row.id].product_id !== 'undefined') changed[row.id].product_name = displayOptionLabel(product_name_list, changed[row.id], 'product_id') 
          if(typeof changed[row.id].warehouse_id !== 'undefined') changed[row.id].warehouse_name = displayOptionLabel(warehouse_id, changed[row.id], 'warehouse_id')
          if(typeof changed[row.id].bin_id !== 'undefined') changed[row.id].storagebin_name = displayOptionLabel(bin_id, changed[row.id], 'bin_id') 
          this.props.onEdit(row.id, changed[row.id])
          return { ...row, ...changed[row.id] }
        }
        return row
      })
    }
    if (deleted) {
      const deletedSet = new Set(deleted);
      rows = rows.filter(row => !deletedSet.has(row.id));
      this.props.onDelete(deleted[0])
    }

    this.setState({ ...this.state, rows:rows });
  }

  prepareOptionList = (companyId) => {
    loadOptions(companyId).then(res => {
      this.setState({ 
        warehouse_id: genOptionList(res[0].warehouse_id, 'id', 'name'),
        product_name_list: genOptionList(res[0].product_id, 'id', 'name'),
        product_code: genOptionList(res[0].product_id, 'id', 'code'),
        uom_id: genRelateOptionList(res[0].product_id, 'uom_id', 'uom_name', 'uom', 'product_id'),
        batch_id: genRelateOptionList(res[0].product_id, 'id', 'name', 'batch', 'product_id'),
        bin_id: genRelateOptionList(res[0].warehouse_id, 'id', 'name', 'bin', 'warehouse_id'),
        isLoading: false
      })
    })
  }

  componentDidUpdate() {
    const { currentUrl } = this.state
  
    if(currentUrl !== window.location.pathname) {
      this.setState({ 
        rows: this.props.rows,
        currentUrl: window.location.pathname,
        company_id: this.props.companyId
       })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { companyId, rows, processType } = nextProps

    let editingStateColumnExtensions = [
      { columnName: 'batch_id', editingEnabled: true },
      { columnName: 'bin_id', editingEnabled: true },
    ]

    if (processType.toString() === '5') {
      editingStateColumnExtensions = [
        { columnName: 'batch_id', editingEnabled: false },
        { columnName: 'bin_id', editingEnabled: false },
      ]

      this.setState({ editingStateColumnExtensions })
    }

    this.setState({ 
      rows: rows,
      currentUrl: window.location.pathname,
      processType,
      company_id: companyId
    })

    if(companyId === this.state.company_id) return
    if(companyId !== '') {
      this.prepareOptionList(companyId)
    }
      
  }

  
  componentDidMount() {
    this._isMounted = true
    const { rows, companyId } = this.props 
    if(this._isMounted) {
        this.setState({ 
          rows: rows,
          currentUrl: window.location.pathname
        }, () => {
          this.prepareOptionList(companyId)
        })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  IsUndefined = (data) => {
    return typeof data === 'undefined' ? true : false
  }

  validateRow = (addedRows, action) => {
    const { processType, rows, curIdx } = this.state
    let cnt = 0, cntPrd = 0
    let data = (action === 'edit') ? addedRows[curIdx] : addedRows[0]
    let type = parseInt(processType)

    let arrName = ['product_id', 'uom_id', 'order_qty', 'warehouse_id']
    let checkStock = ['product_id', 'uom_id', 'warehouse_id']

    if(type === 5) {
        arrName = ['product_id', 'uom_id', 'order_qty', 'warehouse_id', 'bin_id', 'batch_id']
        checkStock = ['product_id', 'uom_id', 'warehouse_id', 'bin_id', 'batch_id']
    }

    this.setState({ disableSave: true, disabledEdit: true })

    if(this.IsUndefined(data)) {
      this.setState({ 
          textQty: '', 
          disabledEdit: false, 
          c_prd : '',
          c_uom : '',
          c_batch : '',
          c_warehouse : '',
          c_bin : '',
      })
      return
    }

    if(action === 'edit') {
      let idx = rows.findIndex(data => data.id === curIdx)
      let res = rows[idx]

      arrName.map(name => {
        if(data.hasOwnProperty(name) === false) {
          data[name] = res[name]
        }  
      })
    }

    const { product_id, uom_id, warehouse_id, batch_id, bin_id, order_qty } = data

    arrName.map(name => {
        if(data.hasOwnProperty(name) === false) return true
          if(data[name] !== '' && data[name] !== null) {
            cnt++
            if(checkStock.includes(name)) {
              cntPrd++
              this.setState({ 
                c_prd : product_id,
                c_uom : uom_id,
                c_batch : batch_id,
                c_warehouse : warehouse_id,
                c_bin : bin_id
              })
            }
        }
    })


    if( (type === 5 && cntPrd === 5) || (type !== 5 && cntPrd === 3) ) {
      let isValueChange = this.checkDiffValue(data, processType)
      let disableSave = true

      if(isValueChange) {

        checkAvailable(product_id, uom_id, warehouse_id, processType, batch_id, bin_id).then(res => {
            let qty = res.data[0].total_base_qty
            this.setState({ textQty: `คงเหลือ ${qty} รายการ`, onhand_qty: qty })

            if( parseFloat(qty) >= parseFloat(order_qty) && parseFloat(order_qty) > 0) {
                 if(cnt >= 4 && type !== 5) disableSave = false
                 if(cnt === 6 && type === 5) disableSave = false
            }
            this.setState({ disableSave: disableSave })

        })
      } else {

        if( parseFloat(this.state.onhand_qty) >= parseFloat(order_qty) && parseFloat(order_qty) > 0) {
          if(cnt >= 4 && type !== 5) disableSave = false
          if(cnt === 6 && type === 5) disableSave = false
        }

        this.setState({ disableSave: disableSave })
      }

    }

  }

  checkDiffValue = (data, processType) => {
    const { c_prd, c_uom, c_batch, c_warehouse, c_bin } = this.state
    const { product_id, uom_id, batch_id, warehouse_id, bin_id } = data
    let cnt = 0

    if(parseInt(processType) === 5) {
      if(this.compareValue(c_prd, product_id)) cnt++
      if(this.compareValue(c_uom, uom_id)) cnt++
      if(this.compareValue(c_batch, batch_id)) cnt++
      if(this.compareValue(c_warehouse, warehouse_id)) cnt++
      if(this.compareValue(c_bin, bin_id)) cnt++

      return (cnt < 5) ? true : false

    } else {
      if(this.compareValue(c_prd, product_id)) cnt++
      if(this.compareValue(c_uom, uom_id)) cnt++
      if(this.compareValue(c_warehouse, warehouse_id)) cnt++

      return (cnt < 3) ? true : false
    }
  }

  getEditID = data => {
    this.setState({ curIdx : data[0] })

  }

  compareValue = (dataA, dataB) => {
    return dataA === dataB
  }



  render() {
    const { rows, columns, editingStateColumnExtensions } = this.state;
    return (
        <Grid
          rows={rows}
          columns={columns}
          getRowId={getRowId}
        >
          <EditingState
            onCommitChanges={this.commitChanges}
            columnExtensions={editingStateColumnExtensions}
            onAddedRowsChange={(addedRows) => this.validateRow(addedRows, 'add')}
            onRowChangesChange={(addedRows) => this.validateRow(addedRows, 'edit')}
            onEditingRowIdsChange={this.getEditID}
          />
          <VirtualTable
            cellComponent={Cell}
            columnExtensions={tableColumnExtensions}
          />

          <TableHeaderRow />
          <TableEditRow cellComponent={this.editCell}/>
          { this.props.docId > 0 && this.props.status === 0 
          && this.props.disabled && checkAction("goodsissue edit") &&
          this.state.isLoading === false && 
            <TableEditColumn
              width={80}
              showAddCommand
              showEditCommand
              showDeleteCommand
              commandComponent={
                props => this.command(props, this.state.disableSave, this.state.disabledEdit)}
            />
          }
        </Grid>
    );
  }
}


DocItems.defaultProps = {
  data : [],
  status: 0
}


