import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { headerWithAuth, handleUnAutherize } from 'lib/authFunc'
import * as actions from './actions'

const  header = headerWithAuth()

function* loadCompanies(action) {
  try {
    const { term } = action.payload
    const res = yield call(
      axios.get,
      `api/companies${term}`,
      header
    )
    yield put(actions.loadCompanies.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadCompanies.failure(error.response))
  }
}

function* loadCompany(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.get, `/api/companies/${id}`, header)
    yield put(actions.loadCompany.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadCompany.failure(error.response))
  }
}

function* createCompany(action) {
  try {
    const { company } = action.payload
    var formData = new FormData();
    formData.append("image", company.image);
   
    const res = yield call(axios.post, '/api/companies', { company }, header)

    if(company.image !== null) {
      yield call(axios.post, `/api/companies/upload/${res.data.id}`, formData, header)
    }

    yield put(actions.createCompany.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.createCompany.failure(error))
  }
}

function* editCompany(action) {

  try {
    const { company, id } = action.payload
    var formData = new FormData();
    formData.append("image", company.image);
    const res = yield call(axios.patch, `/api/companies/${id}`, company, header)

    if(company.image !== null) {
      yield call(axios.post, `/api/companies/upload/${res.data.id}`, formData, header)
    }

    yield put(actions.editCompany.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.editCompany.failure(error.response))
  }
}

function* deleteCompany(action) {
  try {
    const { id } = action.payload
    const res = yield call(axios.delete, `/api/companies/${id}`, header)
    yield put(actions.deleteCompany.success(res.data))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.deleteCompany.failure(error.response))
  }
}


export default function* watchComments() {
  yield all([
    takeEvery(actions.LOAD_COMPANYS.REQUEST, loadCompanies),
    takeEvery(actions.LOAD_COMPANY.REQUEST, loadCompany),
    takeEvery(actions.CREATE_COMPANY.REQUEST, createCompany),
    takeEvery(actions.EDIT_COMPANY.REQUEST, editCompany),
    takeEvery(actions.DELETE_COMPANY.REQUEST, deleteCompany)
  ])
}
