import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/lab/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';

const styles = theme => ({
  root: {
    margin: `5px`,
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing.unit / 2,
    width: 20,
    height: 20,
  },
});

function Breadcrumb(props) {
  const { classes, menu, submenu, menuLink } = props;
  return (
    <Breadcrumbs arial-label="Breadcrumb" className={classes.root} id="bread-crumb">
      <Link color="inherit" href="/home" className={classes.link}>
        <HomeIcon className={classes.icon} />
        หน้าแรก
        </Link>
      {submenu === '' ?
        <Typography color="textPrimary" className={classes.link}>
          {menu}
        </Typography> :
        <Link color="inherit" href={`${menuLink}`} className={classes.link}>
          {menu}
        </Link>
      }
      {submenu !== '' &&
        <Typography color="textPrimary" className={classes.link}>
          {submenu}
        </Typography>
      }

    </Breadcrumbs>
  );
}

Breadcrumb.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Breadcrumb);