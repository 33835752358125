import React from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classnames from 'classnames';

export default ({ classes, color, expanded, handleExpandClick, data }) => {
    return <CardHeader
    avatar={
      <Avatar aria-label="Recipe" style={{ backgroundColor: `${color}` }}>
        {data.alphabet}
      </Avatar>
    }
    // action={
    //   <IconButton
    //     className={classnames(classes.expand, {
    //       [classes.expandOpen]: expanded,
    //     })}
    //     onClick={handleExpandClick}
    //     aria-expanded={expanded}
    //     aria-label="Show more"
    //   >
    //     <ExpandMoreIcon />
    //   </IconButton>
    // }
    title={data.title}
    subheader={data.subheader}
  />
}