import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { headerWithAuth, handleUnAutherize } from 'lib/authFunc'
import * as actions from './actions'

const header = headerWithAuth()

function* loadMaps(action) {
  try {
    const { term } = action.payload
    const { heatmaps } = yield all({
      heatmaps: call(axios.post,
        `api/dashboard/heatmap`,
        term,
        header)
    });
    const res = {
      heatmaps: heatmaps.data
    }
    yield put(actions.loadMaps.success(res))
  } catch (error) {
    handleUnAutherize(error)
    yield put(actions.loadMaps.failure(error.response))
  }
}

export default function* watchComments() {
  yield all([
    takeEvery(actions.LOAD_MAP.REQUEST, loadMaps)
  ])
}
