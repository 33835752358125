import React, { Component, Fragment } from 'react';
import { DatePickerWidget } from 'lib/widgets'
import Grid from '@material-ui/core/Grid';
import { formatDate, genOptionList, getQuarter, getIdtoArray } from 'lib/helper'
import { styles } from '../../../reports/product/RelateFunc'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import { MultipleSelect } from 'lib/widgets'
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel'
import axios from 'axios'
import { headerWithAuth } from 'lib/authFunc'
const header = headerWithAuth()

const defaultValue = {
    users: [],
    compared: 'quarter',
    user_name: null,
    user_id: null,
    start_date_1: null,
    end_date_1: null,
    start_date_2: null,
    end_date_2: null,
    q1: getQuarter() - 1 === 1,
    q2: getQuarter() - 1 === 2,
    q3: getQuarter() - 1 === 3,
    q4: getQuarter() - 1 === 4
}

class CompareFilter extends Component {
    state = {
        ...defaultValue,
        company_id: this.props.companyId,
        branch_id: this.props.branchId
    }
    clearValue = () => {
        this.setState(defaultValue)
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.checked })
    }

    handleChangeFilterType = value => () => {
        this.setState({ 
            compared: value, 
            start_date_1: null,
            end_date_1: null,
            start_date_2: null,
            end_date_2: null,
            q1: false,
            q2: false,
            q3: false,
            q4: false
        })
    }

    handleSearch = () => {
        const { 
            start_date_1, 
            end_date_1, 
            start_date_2, 
            end_date_2, 
            compared,
            q1, q2, q3, q4
        } = this.state
        if (compared === 'date') {
            if (start_date_1 === null && start_date_2 === null 
                && end_date_1 === null && end_date_2 === null) {
                    alert('กรุณาระบุ ช่วงวันที่')
                    return false
            }

            if (start_date_1 === null || start_date_2 === null) {
                alert('กรุณาระบุ วันที่เริ่มต้น')
                return false
            }

            if (end_date_1 === null || end_date_2 === null) {
                alert('กรุณาระบุ วันที่สิ้นสุด')
                return false
            }
        } else {
            if (q1 === false && q2 === false && q3 === false && q4 === false) {
                alert('กรุณากรอกไตรมาส อย่างน้อย 1 ไตรมาส')
                return false
            }
        }

        this.props.onSearch(this.state)
    }

    loadOption = async () => {
        try {
            const res = await axios(`/api/master-data/users?sales=true&branch_id=${this.state.branch_id}`, header)
            if (typeof res.data.data !== 'undefined') {
                this.setState({ users: genOptionList(res.data.data, 'id', 'name') })
            }
        } catch (_) {
            console.log('Error')
        }
    }

    createFilter = val => {
        const {
            start_date_1,
            end_date_1,
            start_date_2,
            end_date_2,
            q1,
            q2,
            q3,
            q4
        } = this.state
        if (val === 'date') {
            return <Grid container spacing={16} justify="space-around" alignItems="center">
                <Grid item sm={2} xs={12}>ช่วงวันที่</Grid>
                <Grid item sm={2} xs={6}>
                    <DatePickerWidget
                        name="start_date_1"
                        value={start_date_1}
                        title="เริ่มต้น"
                        onValueChange={(value, name) => {
                            this.setState({ start_date_1: formatDate(value) })
                        }}
                    />
                </Grid>
                <Grid item sm={2} xs={6}>
                    <DatePickerWidget
                        name="end_date_1"
                        value={end_date_1}
                        title="สิ้นสุด"
                        onValueChange={(value, name) => {
                            this.setState({ end_date_1: formatDate(value) })
                        }}
                    />
                </Grid>
                <Grid item sm={2} xs={12} style={{ textAlign: 'center' }}>เปรียบเทียบกับ</Grid>
                <Grid item sm={2} xs={6}>
                    <DatePickerWidget
                        name="start_date_2"
                        value={start_date_2}
                        title="เริ่มต้น"
                        onValueChange={(value, name) => {
                            this.setState({ start_date_2: formatDate(value) })
                        }}
                    />
                </Grid>
                <Grid item sm={2} xs={6}>
                    <DatePickerWidget
                        name="end_date_2"
                        value={end_date_2}
                        title="สิ้นสุด"
                        onValueChange={(value, name) => {
                            this.setState({ end_date_2: formatDate(value) })
                        }}
                    />
                </Grid>
            </Grid>
        }

        return <Grid container spacing={16} >
            <Grid item sm={2} xs={12}>ไตรมาส</Grid>
            <Grid item sm={9} xs={12}>
                <FormGroup row>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="q1"
                                value="q1"
                                checked={q1}
                                onChange={this.handleChange('q1')}
                            />
                        }
                        label="Q1"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="q2"
                                value="q2"
                                checked={q2}
                                onChange={this.handleChange('q2')}
                            />
                        }
                        label="Q2"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="q3"
                                value="q3"
                                checked={q3}
                                onChange={this.handleChange('q3')}
                            />
                        }
                        label="Q3"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="q4"
                                value="q4"
                                checked={q4}
                                onChange={this.handleChange('q4')}
                            />
                        }
                        label="Q4"
                    />
                </FormGroup>
            </Grid>
        </Grid>
    }

    componentWillReceiveProps(nextProps) {
        const { companyId, branchId } = nextProps
        this.setState({
            company_id: companyId,
            branch_id: branchId
        })
    }

    componentDidMount() {
        this.loadOption()
    }

    render() {
        const {
            users,
            user_name,
            compared
        } = this.state
        const { classes } = this.props

        return (
            <Fragment>
                <Grid container spacing={16} alignItems="center">
                    <Grid item sm={2} xs={4}>เซลล์</Grid>
                    <Grid item sm={4} xs={8}>
                        <FormControl className={classes.formControl} style={{ maxWidth: '80%', marginLeft: '0px' }}>
                        <InputLabel>เลือกทั้งหมด</InputLabel>
                            <MultipleSelect
                                name="user_name"
                                value={user_name || ''}
                                options={users}
                                onChange={(name, value) => {
                                    let items = users.filter(val => value.includes(val.label))
                                    this.setState({ 
                                        user_name: value,
                                        user_id: getIdtoArray(items, 'value').join()
                                    })
                                }} />
                        </FormControl>
                    </Grid>
                    
                </Grid>
                <Grid container spacing={16} >
                    <Grid item sm={2} xs={12}>เปรียบเทียบ</Grid>
                    <Grid item sm={9} xs={12}>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Radio
                                        name="compared"
                                        value="quarter"
                                        checked={compared == 'quarter'}
                                        onChange={this.handleChangeFilterType('quarter')}
                                    />
                                }
                                label="แบบไตรมาส"
                            />
                            <FormControlLabel
                                control={
                                    <Radio
                                        name="compared"
                                        value="date"
                                        checked={compared == 'date'}
                                        onChange={this.handleChangeFilterType('date')}
                                    />
                                }
                                label="แบบวันที่"
                            />
                        </FormGroup>
                    </Grid>
                </Grid>

                {this.createFilter(compared)}

                <Grid container spacing={16} justify="center">
                    <Button
                        variant="contained"
                        color="default"
                        onClick={this.clearValue}
                        className={classes.button}
                    >
                        เคลียร์
                          </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={this.handleSearch}
                        className={classes.button}
                    >
                        ตกลง
                          </Button>

                </Grid>
            </Fragment>
        );
    }
}

export default withStyles(styles)(CompareFilter)