import React, { useState, useEffect } from 'react';
import CalendarHeatmap from 'react-calendar-heatmap'
import ReactTooltip from 'react-tooltip';
import axios from 'axios'
import { config } from 'lib/authFunc'

import { numberFormat, addDate, calcDiffDate } from 'lib/helper'

import 'react-calendar-heatmap/dist/styles.css';

export default function CalendarHeatmapApp({ cond }) {
  const [heatMap, setHeatMap] = useState([])
  const [condStr, setCond] = useState('')
  const [startDate, setStart] = useState(new Date(new Date().getFullYear(), 0, 1))
  const [endDate, setEnd] = useState(new Date(new Date().getFullYear(), 11, 31))

  const fetchData = async () => {
    setCond(cond)
    const result = await axios.post('api/customer-heatmap', cond, config)
    if (typeof result.data !== 'undefined') {
        if (result.data.items.length === 0) {
          setHeatMap(result.data.items)
        } else {
          const { items, start_date, end_date } = result.data
          setHeatMap(items)
          setStart(start_date)
          let diff = calcDiffDate(start_date, end_date)
          let last = end_date;
          if (diff < 364)  last = addDate(start_date, 365)
          setEnd(last)
        }
    }

  }

  useEffect(() => {
    if (cond !== condStr) fetchData()
  })

  return (
    <div>
      <CalendarHeatmap
        startDate={startDate}
        endDate={endDate}
        values={heatMap}
        tooltipDataAttrs={value => {
          let data = value.date !== null ? value.date : ''
          return {
            'data-tip': `${data} ยอด: ${
              (typeof value.net === 'undefined') ? 0 : numberFormat(value.net) } บาท`,
          };
        }}
        showWeekdayLabels={true}
      />
      <ReactTooltip />
    </div>
  );
}

