import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import * as actions from '../actions'
import { connect } from 'react-redux'
import { compose } from 'redux'
import debounce from 'lodash/debounce'

import { formatDate, createCondition } from 'lib/helper'
import { emptySubRow, hasMessage } from 'lib/fxUI'

import {
  ActionBtn,
  ButtonTable,
  ConfirmDlg,
  SearchPanel,
  MenuMobile
} from 'lib/widgets'

import MUIDataTable from 'mui-datatables'
import { MuiThemeProvider } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { Tablestyle } from '../../AddonStyles'
import AdvanceSearch from './AdvanceSearch'

import { subComponent as SubComponent } from './RelateFunc'
import { getGTNextStatus } from '../../helpFx'

import { defaultState } from '../defaultState'

const checkIcon = (
  <i className="fa fa-check-circle" style={{ color: 'green' }} />
)
const crossIcon = (
  <i className="fa fa-exclamation-circle" style={{ color: 'red' }} />
)

const DRAFT = 0,
  CONFIRM = 1,
  PARTIAL = 2

const actionLink = (status, id, onClick) => {
  let Btn = '',
    title = ''
  switch (status) {
    case DRAFT:
      title = 'Confirm'
      break
    case CONFIRM:
      title = 'Cancel'
      break
    case PARTIAL:
      title = 'Close'
      break
    default:
      break
  }

  if (title !== '') {
    return (
      <Link to="#" onClick={() => onClick(id, getGTNextStatus(status))}>{title}</Link>
    )
  }

  return Btn
}

class GoodsTransferTable extends Component {
  state = {
    term: '',
    search: '',
    term_from_advsearch: "",
    delId: '',
    options: {},
    page: 0,
    sortName: '',
    rowsPerPage: 10,
    count: 100,
    isModalOpen: false,
    showAdvSearch: false,
    successSave: null,
    deleteSuccess: null,
    width: window.innerWidth,
  }

  componentWillReceiveProps(nextProps) {
    const { successSave, deleteSuccess } = nextProps

    this.setState({ successSave, deleteSuccess })
  }

  componentDidMount() {
    this.fetchGoodsTransfers()
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  }

  fetchGoodsTransfers = async () => {
    const { term } = this.state
    this.props.loadGoodsTransfers(term)
  }

  delaySearchData = debounce(async value => {
    this.setState({ search: value })
    const cond = 'strict=false&'+createCondition({ order_no: '' }, value)
    this.props.loadGoodsTransfers('?'+cond)
  }, 750)

  searchdata = event => {
    return this.delaySearchData(event)
  }

  setAdvSearch = data => {
    this.setState({ term: data.replace(/\?/g, ''), term_from_advsearch: data.replace(/\?/g, '') })
    this.advSearch(data)
  }

  advSearch = data => {
    this.props.loadGoodsTransfers(data)
  }

  navigateToCreateForm = () => {
    this.props.history.push('/warehouse/goods-transfers/new')
  }

  editForm = id => {
    this.props.history.push(`/warehouse/goods-transfers/${id}`)
  }

  deleteGoodsTransfer = id => {
    const { delId } = this.state
    this.props.deleteGoodsTransfer(delId)
    this.setState({ isModalOpen: false })
  }

  toggleConfirmDlg = (id = '') => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      delId: id,
      successSave: null,
      deleteSuccess: null
    })
  }

  showAdvSearch = state => {
    this.setState({ showAdvSearch: state })
  }

  getRealColumn = name => {
    let col = ''

    switch (name) {
      case 'fullname':
        col = 'name'
        break
      case 'doctype':
        col = 'document_type_id'
        break
      case 'status_desc':
        col = 'status'
        break
      default:
        col = name
        break
    }

    return col
  }

  sortingData = (changedColumn, direction) => {
    let sortby = this.getRealColumn(changedColumn)
    const { page, rowsPerPage, search } = this.state
    const sort = direction === 'ascending' ? 'asc' : 'desc'
    let condStr = 'strict=false&'+createCondition({ order_no: '' }, search)
    if(this.state.term_from_advsearch !== "") condStr += "&"+this.state.term_from_advsearch

    let term = `?page=${page}&per_page=${rowsPerPage}&sort_by=${sortby}&sort=${sort}&${condStr}`
    this.setState(
      {
        term: term,
        sortName: changedColumn,
        options: { sort: true, sortDirection: sort }
      },
      this.advSearch(term)
    )
  }

  handleTableChange = (action, tableState) => {
    let term = ''
    let condStr = 'strict=false&'+createCondition({ order_no: '' }, this.state.search)
    if(this.state.term_from_advsearch !== "") condStr += "&"+this.state.term_from_advsearch
    switch (action) {
      case 'changeRowsPerPage':
        term = `?per_page=${tableState.rowsPerPage}&${condStr}`
        this.setState(
          {
            term: term,
            rowsPerPage: tableState.rowsPerPage,
            page: 0
          },
          this.advSearch(term)
        )
        break
      case 'changePage':
        term = `?page=${tableState.page + 1}&per_page=${tableState.rowsPerPage}&${condStr}`
        this.setState(
          {
            term: term,
            page: tableState.page
          },
          this.advSearch(term)
        )
        break
      default:
        break
    }
  }

  handleClickRow = id => {
    let ele = document.getElementById(id)
    let show = ele.style.display === 'none' ? '' : 'none'
    ele.removeAttribute('style')
    ele.style.display = show
  }

  handleChangeStatus = (id, status) => {
    this.props.changeStatusGT(id, status)
  }

  resetSaveState = () => {
    this.setState({ 
      successSave: null,
      deleteSuccess: null 
    })
  }

  renderTable = (isLoading, columns, goodstransfers, optionsTable) => {
    const isMobile = this.state.width <= 500

    if(isMobile) {
      return <MenuMobile 
      data={goodstransfers} 
      columns={{
        col1: 'movement_date',
        col2: 'order_no',
        col3: 'source_by',
        col4: 'status_desc',
      }}
      title={{
        title1: () => 'ทำรายการล่าสุด',
        title2: (val) => val.empty_stock_balance > 0 ? crossIcon : checkIcon,
        title3: () =>  'ที่มา',
        title4: () =>  'สถานะ'
      }}
      linkUrl={`/warehouse/goods-transfers`}
      onLoad={this.advSearch}
      url={`/api/goodstranfers`}
      deleteOnly={false}
      onDelete={this.toggleConfirmDlg}
      actionLink={actionLink}
      successSave={this.props.successSave}
      deleteSuccess={this.props.deleteSuccess}
      changeStatus={this.handleChangeStatus}
      />
    } 

    if(typeof goodstransfers === 'object')
      return <Fragment>
      { isLoading && <div className="lmask" id="lmask" /> }
      <MuiThemeProvider theme={Tablestyle()}>
        <MUIDataTable
          title={'ข้อมูลการโอนสินค้า'}
          data={goodstransfers}
          columns={columns}
          options={optionsTable}
        />
      </MuiThemeProvider>
    </Fragment>
    
  }

  render() {
    const { isLoading, goodstransfers, meta, errorMsg } = this.props
    const {
      rowsPerPage,
      page,
      sortName,
      options,
      successSave,
      deleteSuccess
    } = this.state
    const count = typeof meta === 'undefined' ? 100 : meta.total
    const columns = [
      {
        name: '',
        label: '#',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return page * rowsPerPage + tableMeta.rowIndex + 1
          }
        }
      },
      { label: 'เลขที่เอกสาร', name: 'order_no' },
      { label: 'เอกสารอ้างอิง', name: 'ref_no' },
      { label: 'ชนิดเอกสาร', name: 'document_type' },
      { label: 'ประเภทการตัดสต๊อก', name: 'process_type' },
      {
        label: 'ทำรายการล่าสุด',
        name: 'movement_date',
        options: { customBodyRender: row => formatDate(row) }
      },
      { label: 'สถานะ', name: 'status_desc' },
      { label: 'ที่มา', name: 'source_by' },
      {
        label: ' ',
        name: 'empty_stock_balance',
        options: {
          customBodyRender: row => (row > 0 ? crossIcon : checkIcon)
        }
      },
      {
        label: ' ',
        name: 'id',
        options: {
          customBodyRender: (row, tableState) => {
            if (typeof tableState.rowData === 'object') {
              let val = tableState.rowData
              return actionLink(val[11], row, this.handleChangeStatus)
            }
          }
        }
      },
      {
        name: 'id',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            let val = tableMeta.rowData
            return (
              <ButtonTable
                id={value}
                onOpen={this.editForm}
                delDisable={val[11] !== 0 ? true : false}
                onDelete={() => this.toggleConfirmDlg(value)}
                view="goodstransfer delete"
              />
            )
          }
        }
      },
      { label: '', name: 'status', options: { display: false } }
    ]

    const subRow = [
      { label: 'สินค้า', name: 'product_name' },
      { label: 'จำนวนที่สั่ง', name: 'order_qty' },
      { label: 'จำนวนที่ย้าย', name: 'accumulated_qty' },
      { label: 'จำนวนที่เหลือ', name: 'remain_qty' },
      { label: 'หน่วยนับ', name: 'uom_name' },
      { label: '', name: 'summary', show: false }
    ]

    const subRow2 = [
      { label: 'Batch', name: 'batch' },
      { label: 'จำนวนที่สั่ง', name: 'sum_order_qty' },
      { label: 'จำนวนที่จ่าย', name: 'sum_acc_qty' },
      { label: 'จำนวนที่เหลือ', name: 'sum_remain_qty' },
      { label: 'คลังสินค้าต้นทาง', name: 's_warehouse_name' },
      { label: 'สถานที่จัดเก็บต้นทาง', name: 's_storage_name' },
      { label: 'คลังสินค้าปลายทาง', name: 'd_warehouse_name' },
      { label: 'สถานที่จัดเก็บปลายทาง', name: 'd_storage_name' }
    ]

    const optionsTable = {
      filter: false,
      print: false,
      download: false,
      viewColumns: false,
      filterType: 'dropdown',
      serverSide: true,
      count: count,
      page: page,
      expandableRows: true,
      rowsPerPage: rowsPerPage,
      selectableRows: false,
      onTableChange: this.handleTableChange,
      onSearchChange: this.searchdata,
      onColumnSortChange: this.sortingData,
      onSearchClose: () => {
        this.setState({ search : '' })
        this.advSearch(this.state.term)
      },
      textLabels: {
        body: {
          noMatch: "Sorry, no matching records found",
          toolTip: "Sort",
        }
      },
      renderExpandableRow: (rowData, rowMeta) => {
        const items = goodstransfers[rowMeta.dataIndex].relations.detailGroup
        const colSpan = rowData.length + 1
        if (items.length === 0) return emptySubRow(colSpan)
        return (
          <SubComponent
            colSpan={colSpan}
            subRow={subRow}
            items={items}
            rowMeta={rowMeta}
            subRow2={subRow2}
            handleClickRow={this.handleClickRow}
          />
        )
      },
      customToolbar: () => {
        return (
          <ActionBtn
          onCreate={this.navigateToCreateForm}
          onSearch={this.showAdvSearch}
          view="goodstransfer edit"
        />
        );
      }
    }

    if (sortName !== '') {
      let idx = columns.findIndex(data => data.name === sortName)
      if (idx === -1) return
      columns[idx].options = options
    }


    return (
      <Fragment>

          <Fragment>
            {successSave !== null && hasMessage(successSave, errorMsg, this.resetSaveState)}
            {deleteSuccess !== null && hasMessage(deleteSuccess, 'ลบข้อมูลสำเร็จ', this.resetSaveState)}

              { isLoading && <div className="lmask" id="lmask" /> }

                <Grid container spacing={24}>
                    <Grid item xs style={{ textAlign: 'right' }}>
                      {crossIcon} สินค้าไม่ครบ &nbsp;&nbsp;&nbsp; {checkIcon}
                      สินค้าครบ
                    </Grid>
                    <Grid item md={12}>
                      { this.renderTable(isLoading, columns, goodstransfers, optionsTable) }
                    </Grid>
                  
                </Grid>
          </Fragment>

        <ConfirmDlg
          title="ยืนยันการลบข้อมูล"
          content="คุณต้องการลบข้อมูลนี้ ?"
          onToggle={this.toggleConfirmDlg}
          onConfirm={this.deleteGoodsTransfer}
          isOpen={this.state.isModalOpen}
        />
        <SearchPanel
          toggleDrawer={this.showAdvSearch}
          isOpen={this.state.showAdvSearch}
          onSubmit={this.setAdvSearch}
          Component={AdvanceSearch}
          formValue={defaultState.advSearch}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ goodstransfers }) => ({
  isLoading: goodstransfers.isLoading,
  goodstransfers: goodstransfers.items,
  meta: goodstransfers.meta,
  backupItems: goodstransfers.backupItems,
  successSave: goodstransfers.successSave,
  deleteSuccess: goodstransfers.deleteSuccess,
  errorMsg: goodstransfers.errorMsg
})

const mapDispatchToProps = {
  changeStatusGT: actions.changeStatusGT.request,
  loadGoodsTransfers: actions.loadGoodsTransfers.request,
  deleteGoodsTransfer: actions.deleteGoodsTransfer.request,
  filterData: actions.filterData
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(GoodsTransferTable)
