import React, { Component, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { SelectWidget } from 'lib/widgets'
import { Line } from 'react-chartjs-2';
import axios from 'axios'
import { config, handleUnAutherize } from 'lib/authFunc'
import CusGroupTable from './CusGroupTable'

export default class LineChart extends Component {
    state = {
        year: '0001',
        all: true,
        yaerOpt: []
    }

    getYear = async () => {
        try {
            const result = await axios.get('api/getyear', config)
            if (typeof result.data !== 'undefined') {
                let opt = [{ value: '0001', label: 'ทุกปี' }, ...this.createOpt(result.data)]
                this.setState({ yaerOpt: opt })
            }
        } catch (error) {
            handleUnAutherize(error)
        }
    }

    handleChange = e => {
        this.setState({ all : e.target.checked })
    }

    genTitle = value => {
        if (value === '0001') return `ลูกค้าทั้งหมด`
        return `ลูกค้าปี ${value} ทั้งหมด`
    }

    createOpt = arr => {
        let options = []
        if (typeof arr !== 'object') return

        arr.map(data => {
            return options.push({ value: data, label: data })
        })

        return options
    }

    componentDidMount() {
        let cond = `branch_id=${this.props.branchId}&company_id=${this.props.companyId}`
        this.props.onLoad(`?year=0001&${cond}`)
        this.getYear()
    }

    componentWillReceiveProps(nextProps) {
        const { companyId, branchId } = nextProps
        let cond = `branch_id=${branchId}&company_id=${companyId}`

        if (companyId !== this.props.companyId || branchId !== this.props.branchId) {
            this.props.onLoad(`?year=0001&${cond}`)
        }
    }

    genGraphData = () => {
        return {
            labels: this.props.label,
            datasets: [
                {
                    label: 'จำนวนลูกค้า',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: this.props.data
                }
            ]
        }
    }

    genSummary = () => {
        if (typeof this.props.data === 'undefined') return 0
        return this.props.data.reduce((a, b) => a + b, 0)
    }
    render() {
        return (
            <Fragment>
                <Grid container spacing={16} justify="flex-end">
                    <Grid item sm={3} xs={12}>
                        <SelectWidget
                            isClear={false}
                            withOption={true}
                            options={this.state.yaerOpt}
                            value={this.state.year}
                            title="ช่วงเวลา (ปี)"
                            onChange={(name, value) => {
                                let cond = `branch_id=${this.props.branchId}&company_id=${this.props.companyId}`
                                this.setState({ year: value },
                                    () => this.props.onLoad(`?year=${value}&${cond}`))
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={16}>
                    <Grid item xs={12} sm={8}>
                        <Line data={this.genGraphData()} />
                    </Grid>
                    <Grid item xs={12} sm={4} >
                        <CusGroupTable data={this.props.detail}/>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}