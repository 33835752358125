import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import {  getMonthTH, numberFormat } from 'lib/helper'
import axios from 'axios'
import { config } from 'lib/authFunc'


export default ({ classes, companyId, branchId, MonthId }) => {

    const [data, setData] = useState({
        month : 0,
        prev_month: 0,
        year: 0,
        discount: 0,
        net: 0,
        total_free: 0,
        total_qty: 0
    })


    const [loading, setLoading] = useState(false)
    const [company_id, setCompany] = useState('')
    const [branch_id, setBranch] = useState('')

    const fetchData = async (val) => {

        const data = {
            company_id: companyId,
            branch_id: branchId,
            month_id: MonthId,
            format: val
        }

        setLoading(true)
        setCompany(companyId)
        setBranch(branchId)

        const result = await axios.post('api/dashboard/sales-summary', data, config)
        if (typeof result.data !== 'undefined') {
            setData(result.data)
        }
    }

    useEffect(() => {
        if (loading === false || (companyId !== company_id || branchId !== branch_id) )  fetchData()
    })

    return <Grid container spacing={16}>
      <Grid item xs={12} sm={4}>
        <div className="card-dashboard card-dashboard-1">
            <Typography component="h6" variant="h6">{ numberFormat(data.month) }</Typography>
            <Typography component="p">ยอดสะสมเดือน {getMonthTH()}</Typography>
        </div>
      </Grid>
      <Grid item xs={12} sm={4}>
        <div className="card-dashboard card-dashboard-2">
            <Typography component="h6" variant="h6">{ numberFormat(data.prev_month) }</Typography>
            <Typography component="p" >ยอดขายเดือนที่แล้ว</Typography>
        </div>
      </Grid>
      <Grid item xs={12} sm={4}>
        <div className="card-dashboard card-dashboard-3">
            <Typography component="h6" variant="h6">{ numberFormat(data.year) }</Typography>
            <Typography component="p" >ยอดขายสะสมปีนี้</Typography>
        </div>
      </Grid>
  </Grid>
}