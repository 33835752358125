import React, { Component } from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import { config, handleUnAutherize } from 'lib/authFunc'
import axios from 'axios'
import Pusher from 'pusher-js'
import { PUSHER_KEY } from 'lib/defaultSystemData'

export default class NotiBadge extends Component {
    constructor(props) {
        super(props)
        this._pusher = null
        this.state = {
            noti: [],
            newMsg: false
        }       
    }

    notify = () => {
        this._pusher = new Pusher(PUSHER_KEY, {
            cluster: 'ap1',
            encrypted: false
        })
        var channel = this._pusher.subscribe('stock-notify');

        const event = ['ExpiredNotify', 'LowerBoundNotify', 'UpperBoundNotify']
        event.map(name => {
            channel.bind(`App\\Events\\${name}`, (data) => {
                console.log('james2')
                this.setState({ newMsg: true })
            })
        })
    }

    checkNewNoti = async () => {
        try {
          let res = await axios.get(`/api/check-noti`, config)
          if(res.data.result > 0) {
            this.setState({ newMsg: true })
          }
        } catch(err) {
            handleUnAutherize(err)
        }
      }

    componentDidMount() {
        this.notify()
        this.checkNewNoti()
    }

    componentWillUnmount() {
        this._pusher.disconnect()
    }

    render() {
        return (
            this.state.newMsg === false ? <NotificationsIcon /> :
            <Badge badgeContent={"new"} color="secondary">
                <NotificationsIcon />
            </Badge>
        );
    }
}
