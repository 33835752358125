import React, { Fragment } from 'react'
import Grid from '@material-ui/core/Grid'

import TextField from '@material-ui/core/TextField'
import { SelectWidget, DatePickerWidget } from 'lib/widgets'
import { formatDate } from 'lib/helper'

const style = {
    marginBottom: '15px'
}
export default ({ values, classes, handleChange, handleSelect, handleDateChange }) => (
  <div style={{ padding: '10px', flexGrow: 1 }}>
    <Grid item xs={12}  style={style}>
    <Grid item xs={12}  style={style}>
      <TextField
        label="เลขที่เอกสาร"
        name="order_no"
        value={values.order_no}
        onChange={handleChange('order_no')}
        fullWidth
      />
    </Grid>
    <SelectWidget
        url="api/branches"
        value={values.s_branch_id}
        name="s_branch_id"
        title="สาขา"
        onChange={handleSelect('s_branch_id')}
    />
    </Grid>
    <Grid item xs={12} style={style}>
    <SelectWidget
        url="api/master-data/warehouses"
        value={values.warehouse_id}
        name="warehouse_id"
        title="คลังสินค้า"
        fullWidth
        onChange={handleSelect('warehouse_id')}
    />
    </Grid>
  </div>
)
