import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FilterList from '@material-ui/icons/FilterList';
import Button from '@material-ui/core/Button';
import { serializeObj } from 'lib/helper'

const style = {
    borderLeft: '1px dashed #b3b3b3',
    height: 'auto',
    marginRight: '20px',
    marginBottom: '13px'
}

class MapFilter extends React.Component {
    state = {
        old: true,
        old_connect: true,
        old_disconnect: true,
        new: true,
        new_connect: true,
        new_disconnect: true,
    }

    handleFilter = () => {
        this.props.onFilter(this.state)
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.checked }, this.isCheckAll);

        if (name === 'old') {
            this.setState({
                old_connect: event.target.checked,
                old_disconnect: event.target.checked
            })
        } else if (name === 'new') {
            this.setState({
                new_connect: event.target.checked,
                new_disconnect: event.target.checked
            })
        }
    }
    isCheckAll = () => {
        if (this.state.old_connect === false && this.state.old_disconnect === false) {
            this.setState({ old: false })
        }

        if (this.state.old_connect === true || this.state.old_disconnect === true) {
            this.setState({ old: true })
        }

        if (this.state.new_connect === false && this.state.new_disconnect === false) {
            this.setState({ new: false })
        }

        if (this.state.new_connect === true || this.state.new_disconnect === true) {
            this.setState({ new: true })
        }
    }

    render() {

        return (
            <FormGroup row style={{ marginLeft: '12px' }}>
                <FormControlLabel
                    style={{ marginLeft: '0px' }}
                    control={
                        <FilterList />
                    }
                    label="การคัดกรอง"
                />

                <FormControlLabel
                    style={{ marginLeft: '0px' }}
                    control={
                        <Checkbox
                            checked={this.state.new}
                            onChange={this.handleChange('new')}
                            value="new"
                        />
                    }
                    label="ลูกค้าใหม่"
                />
                <FormControlLabel
                    style={{ marginLeft: '0px' }}
                    control={
                        <Checkbox
                            checked={this.state.new_connect}
                            onChange={this.handleChange('new_connect')}
                            value="new_connect"
                        />
                    }
                    label="มียอดปีนี้"
                />
                <FormControlLabel
                    style={{ marginLeft: '0px' }}
                    control={
                        <Checkbox
                            checked={this.state.new_disconnect}
                            onChange={this.handleChange('new_disconnect')}
                            value="new_disconnect"
                            style={{ color: '#ffbd7a' }}
                        />
                    }
                    label="ไม่มียอดปีนี้"
                />

                <div style={style}></div>

                <FormControlLabel
                    style={{ marginLeft: '0px' }}
                    control={
                        <Checkbox
                            checked={this.state.old}
                            onChange={this.handleChange('old')}
                            value="old"
                            color="primary"
                        />
                    }
                    label="ลูกค้าเก่า"
                />
                <FormControlLabel
                    style={{ marginLeft: '0px' }}
                    control={
                        <Checkbox
                            checked={this.state.old_connect}
                            onChange={this.handleChange('old_connect')}
                            value="old_connect"
                            color="primary"
                        />
                    }
                    label="มียอดปีนี้"
                />
                <FormControlLabel
                    style={{ marginLeft: '0px' }}
                    control={
                        <Checkbox
                            checked={this.state.old_disconnect}
                            onChange={this.handleChange('old_disconnect')}
                            value="old_disconnect"
                            color="primary"
                            style={{ color: '#a9bbda' }}
                        />
                    }
                    label="ไม่มียอดปีนี้"
                />

                <FormControlLabel
                    style={{ marginLeft: '15px' }}
                    control={
                        <Button variant="contained" color="primary" onClick={this.handleFilter}>
                            ค้นหา
                        </Button>
                    }
                    label=""
                />

            </FormGroup>
        );
    }
}


export default MapFilter;