import { formatDate } from 'lib/helper'

const initDate = new Date()

const defaultState = {
  id: '',
  cntComp: 0,
  cnt: 0,
  dlgContent: '',
  isModalOpen: false,
  disableForm: false,
  formLoading: true,
  company_id: '',
  branch_id: '',
  order_no: '',
  document_type_id: '',
  process_type_id: '',
  ref_no: '',
  delivery_date: initDate,
  movement_date: '',
  description: '',
  customer_name: '',
  branchUrl: 'api/branches',
  currentId: '',
  companies: [],
  bkcompany: [],
  branches: [],
  relations: {
    detail: []
  },
  columns: [
    { name: 'product_id', title: 'สินค้า * ' },
    { name: 'uom_id', title: 'หน่วยนับ * ' },
    { name: 'warehouse_id', title: 'คลังสินค้า * ' },
    { name: 'batch_id', title: 'Batch No. *' },
    { name: 'bin_id', title: 'Storage Bin *' },
    { name: 'order_qty', title: 'จำนวน * ' },
  ],
  advSearch : {
    company_id: '',
    branch_id: '',
    delivery_date: null,
    status: '',
    ref_no: '',
    order_no: ''
  }

}

const tableColumnExtensions = [
  { columnName: 'product_id', width: 240 },
  { columnName: 'uom_id', width: 116 },
  { columnName: 'warehouse_id', width: 130 },
  { columnName: 'batch_id', width: 116 },
  { columnName: 'bin_id', width: 130 },
  { columnName: 'order_qty', width: 100 },
]

export {
  tableColumnExtensions,
  defaultState
}
