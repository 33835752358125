import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';

function ConfirmDlg(props) {
  const { fullScreen=true, isOpen, title, content, onToggle, onConfirm } = props;
  return (
      <Dialog
        fullScreen={fullScreen}
        open={isOpen}
        onClose={onToggle}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{ title }</DialogTitle>
        <DialogContent>
          <DialogContentText>
            { content }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onToggle} color="secondary">
            ยกเลิก
          </Button>
          <Button onClick={onConfirm} color="primary" autoFocus>
            ยืนยัน
          </Button>
        </DialogActions>
      </Dialog>
  );
}

ConfirmDlg.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(ConfirmDlg);