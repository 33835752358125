import React from 'react';
import axios from 'axios'
import { config } from 'lib/authFunc'

import {
    VirtualTable
  } from '@devexpress/dx-react-grid-material-ui';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';

const getRowId = row => row.id;

const loadOptions = async () => {
    try {
        const uomUrl = axios('/api/master-data/uoms', config)
        const warehouse = axios('/api/master-data/warehouses', config)
    
        const [ uoms, warehouses ] = await Promise.all([uomUrl, warehouse])
        let options = [{ 
            uom_id : uoms.data.data,
            default_wh : warehouses.data.data
         }]

        return options
    } catch(_) {
        console.log('Error')
    }
}

const AddButton = ({ onExecute, disableEdit }) => (
    <div style={{ textAlign: 'center' }}>
      <Button
        color="primary"
        onClick={onExecute}
        title="Create new row"
        disabled={disableEdit}
      >
        New 
      </Button>
    </div>
  );
  
  const EditButton = ({ onExecute, disableEdit }) => (
    <IconButton onClick={onExecute} title="Edit row" disabled={disableEdit}>
      <EditIcon />
    </IconButton>
  );
  
  const DeleteButton = ({ onExecute, disableEdit, disableDel }) => (
    <IconButton onClick={onExecute} 
      title="Delete row" 
      disabled={disableEdit || disableDel}>
      <DeleteIcon />
    </IconButton>
  );
  
  const CommitButton = ({ onExecute, disabled }) => (
    <IconButton onClick={onExecute} title="Save changes" disabled={disabled}>
      <SaveIcon />
    </IconButton>
  );
  
  const CancelButton = ({ onExecute}) => (
    <IconButton color="secondary" onClick={onExecute} title="Cancel changes">
      <CancelIcon />
    </IconButton>
  );
  
  const Cell = props => {
    const { column, value, row } = props;

    if (column.name === 'is_favorite') {
      return <TableCell>
              <Checkbox 
                color="primary" 
                value="1"
                checked={value===1}/>
            </TableCell> 
    }
  
    if (column.name === 'uom_id') {
      return <TableCell>{ row.uom_name }</TableCell>
    }
  
    if (column.name === 'default_wh') {
      return <TableCell>{ row.warehouse_name }</TableCell>
    }

    if (column.name === 'from_uom_id') {
      return <TableCell>{ row.from_uom_name }</TableCell>
    }
  
    return <VirtualTable.Cell {...props} />;
  }
  
  const commandComponents = {
    add: AddButton,
    edit: EditButton,
    delete: DeleteButton,
    commit: CommitButton,
    cancel: CancelButton,
  };
  

export {
    loadOptions,
    AddButton,
    EditButton,
    DeleteButton,
    CommitButton,
    CancelButton,
    Cell,
    commandComponents,
    getRowId
}