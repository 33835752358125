import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { makeStyles, useTheme } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'

import axios from 'axios'
import { config } from 'lib/authFunc'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    minWidth: '100%',
    maxWidth: '200px'
  },
  input: {
    display: 'flex',
    padding: 0
  },
  valueContainer: {
    display: 'flex',
    // flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16,
    whiteSpace: 'nowrap'
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing.unit * 2
  },
  typography: { useNextVariants: true },
}))

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  )
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  )
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  )
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
}

function SelectWidget(props) {
  const { title, url, label, withOption, options, onChange, name, value, disabled } = props
  //const [single, setSingle] = React.useState(null)
  const [data, setData] = useState([])
  const [isLoading, setLoad] = useState(false)
  
  const classes = useStyles()
  const theme = useTheme()

  let _isMounted = false;

  const fetchData = async () => {
    setLoad(true)

    const result = await axios.get(url, config)
    if (typeof result.data.data !== 'undefined') {
      if (_isMounted) {
        setData(result.data.data)
        setLoad(false)
      }
    }
  }

  useEffect(() => {
    _isMounted = true
    if (!withOption) fetchData()

    return () => _isMounted = false
  }, [])

  const genList = items => {
    let arr = [],
      labelOpt = typeof label !== 'undefined' ? label : 'name'
    if (typeof items === 'object') {
      Object.keys(items).reduce((obj, key) => {
        return arr.push({
          value: items[key].id,
          label: items[key][`${labelOpt}`]
        })
      }, {})
      return arr
    }
  }

  function handleChangeSingle(value) {
    //setSingle(value)
    if (typeof onChange === 'function') {
      const val = value === null ? '' : value.value
      onChange(name, val)
    }
  }

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit'
      }
    })
  }

  const getValue = () => {
    if(typeof optionsList !== 'object') return []
    if(value === 'all') {
      return { value: 'all', label: 'เลือกทั้งหมด' }
    }
    return optionsList.filter((item) => parseInt(item.value) === parseInt(value))
  }

  const optionsList = withOption ? options : genList(data)
  return (
    <div className={classes.root}>
      <Select
        name={props.name}
        isClearable={props.isClear}
        isDisabled={disabled}
        isLoading={isLoading}
        classes={classes}
        styles={selectStyles}
        options={optionsList}
        components={components}
        textFieldProps={{
          label: title,
          InputLabelProps: {
            shrink: true
          },
          disabled: disabled
        }}
        value={getValue()}
        getOptionLabel={({label}) => label}
        getOptionValue={({value}) => value}
        onChange={handleChangeSingle}
      />
    </div>
  )
}

SelectWidget.defaultProps = {
  isClear : true
}

export default SelectWidget
