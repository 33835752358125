import React, { Component, Fragment } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'

import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

import { DatePickerWidget, MultipleSelect, SelectWidget, Breadcrumb } from 'lib/widgets'

import { styles, loadOptions, defaultState } from './RelateFunc'
import { genOptionList, genRelateOptionList, formatDate, getIdtoArray } from 'lib/helper'

import axios from 'axios'
import { headerWithAuth } from 'lib/authFunc'

class ShopApp extends Component {
  _isMounted = false
  state = defaultState

  handleExport = async values => {
    this.setState({ isLoading: true })
    try {
      const response = await axios.post(
        '/api/report/stores',
        values,
        headerWithAuth()
      )
      const link = document.createElement('a')
      link.href = response.data
      let fileName = `store_report.xlsx`
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      this.setState({ isLoading: false })
    } catch (error) {
      console.error(error)
    }
  }

  handleChange = (name, data) => {
    this.setState({ [name]: data }, () => this.setRelateOption(name))
  }

  getShopList = (items, value) => {
    let list = []
    items.map((data, i) => {
      if (value.includes(data.name)) {
        data.idx = i
        list.push(data)
      }
    })

    let shop = []
    list.map(data => {
      shop = [...shop, ...data.relations.shop]
    })

    return shop
  }

  setRelateOption = name => {

    if (name === 'customer_group') {
      let shop = this.getShopList(this.state.bkcustomergroup, this.state[name])

      this.setState({
        shop: genOptionList(
          shop,
          'id',
          'name'
        )
      })
      return
    }

    let items =
      name === 'company_id' ? this.state.bkcompany : this.state.bkcustomergroup
    let idx = items.findIndex(
      data => parseInt(data.id) === parseInt(this.state[name])
    )
    if (idx < 0) return

    if (name === 'company_id') {
      this.setState({
        branches: genRelateOptionList(
          [items[idx]],
          'id',
          'name',
          'branch',
          'company_id'
        )
      })
    }
    // } else {
    //   this.setState({
    //     shop: genRelateOptionList(
    //       [items[idx]],
    //       'id',
    //       'name',
    //       'shop',
    //       'customer_group'
    //     )
    //   })
    // }
  }


  componentDidMount() {
    this._isMounted = true
    loadOptions().then(res => {
      if (this._isMounted) {
          this.setState({
            companies: genOptionList(res[0].companies, 'id', 'name'),
            customergroup: genOptionList(res[0].customergroups, 'id', 'name'),
            category: genOptionList(res[0].categories, 'id', 'name'),
            bkcustomergroup: res[0].customergroups,
            bkcompany: res[0].companies,
            loadopt: false
          })
      }
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { classes } = this.props
    const {
      companies,
      branches,
      customergroup,
      shop,
      category,
      isLoading,
      loadopt
    } = this.state

    return (
      <Grid container spacing={24}>
        <Grid item xs={12} style={{ marginTop: '20px', marginLeft: '20px' }}>
          <Breadcrumb
            menu="รายงาน"
            submenu="รายงานยอดขายแยกตามร้านค้า"
            menuLink="/report/stores"
          />
        </Grid>
        <Grid item xs={12}>
          <Formik
            initialValues={defaultState}
            validationSchema={yup.object().shape({
              company_id: yup.string().required('กรุณากรอก'),
              branch_id: yup.string().required('กรุณากรอก'),
              customer_group: yup.array()
              .nullable()
              .required('กรุณากรอก'),
              product_type_id:yup.array()
              .nullable()
              .required('กรุณากรอก'),
              date_start: yup.string().required('กรุณากรอก'),
              date_end: yup.string().required('กรุณากรอก')
            })}
            onSubmit={values => this.handleExport(values)}
            render={({
              values,
              errors,
              touched,
              resetForm,
              handleSubmit,
              setFieldValue
            }) => (
                <Fragment>
                  {isLoading && <div className="lmask" id="lmask" />}
                  <form className={classes.layout}>
                    <Paper className={classes.paper}>
                      <Typography variant="h6" gutterBottom>
                        รายงานยอดขายแยกตามร้านค้า
                  </Typography>
                      <Grid container spacing={24}>
                        <Grid item xs={12} sm={6}>
                          <FormControl className={classes.formControl}>
                            <SelectWidget
                              withOption={true}
                              options={companies}
                              value={values.company_id || ''}
                              name="company_id"
                              title="บริษัท*"
                              disabled={this.state.disableForm}
                              onChange={(name, value) => {
                                setFieldValue(name, value)
                                this.handleChange('company_id', value)
                              }}
                              isLoading={loadopt}
                            />
                            {touched.company_id && errors.company_id && (
                              <span className="form-text--error">
                                {errors.company_id}
                              </span>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl className={classes.formControl}>
                            <SelectWidget
                              withOption={true}
                              options={branches}
                              value={values.branch_id || ''}
                              name="branch_id"
                              title="สาขา*"
                              onChange={setFieldValue}
                            />
                            {touched.branch_id && errors.branch_id && (
                              <span className="form-text--error">
                                {errors.branch_id}
                              </span>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl className={classes.formControl}  style={{ maxWidth: '80%' }}>
                            <InputLabel>กลุ่มลูกค้า *</InputLabel>
                            <MultipleSelect
                              name="customer_group"
                              value={values.customer_group || ''}
                              options={customergroup}
                              onChange={(name, value) => {
                                setFieldValue(name, value)
                                this.handleChange('customer_group', value)
                              }} />
                          {touched.customer_group && errors.customer_group && (
                            <span className="form-text--error">
                              {errors.customer_group}
                            </span>
                          )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl className={classes.formControl} style={{ maxWidth: '80%' }}>
                            <InputLabel>ประเภทสินค้า *</InputLabel>
                            <MultipleSelect
                              name="product_type_id"
                              value={values.product_type_id || ''}
                              options={category}
                              onChange={(name, value) => {
                                setFieldValue(name, value)
                                this.handleChange('product_type_id', value)
                              }} />
                           {touched.product_type_id && errors.product_type_id && (
                            <span className="form-text--error">
                              {errors.product_type_id}
                            </span>
                          )}
                          </FormControl>
                          {/* <FormControl className={classes.formControl}>
                            <SelectWidget
                              withOption={true}
                              options={category}
                              value={values.product_type_id || ''}
                              name="product_type_id"
                              title="ประเภทสินค้า"
                              onChange={setFieldValue}
                            />
                          </FormControl> */}
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl className={classes.formControl}>
                            <SelectWidget
                              withOption={true}
                              options={[{ value: 'all', label: 'เลือกทั้งหมด' }, ...shop]}
                              value={values.shop_id || ''}
                              name="shop_id"
                              title="ร้านค้า"
                              onChange={setFieldValue}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl className={classes.formControl}>
                            <DatePickerWidget
                              name="date_start"
                              title="วันเริ่มต้น *"
                              value={values.date_start || ''}
                              format="dd-MM-yyyy"
                              onValueChange={(data, name) => {
                                setFieldValue(name, formatDate(data, 'd-m-Y'))
                              }}
                            />
                            {touched.date_start && errors.date_start && (
                              <span className="form-text--error">
                                {errors.date_start}
                              </span>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl className={classes.formControl}>
                            <DatePickerWidget
                              name="date_end"
                              title="วันสิ้นสุด *"
                              value={values.date_end || ''}
                              format="dd-MM-yyyy"
                              onValueChange={(data, name) => {
                                setFieldValue(name, formatDate(data, 'd-m-Y'))
                              }}
                            />
                            {touched.date_end && errors.date_end && (
                              <span className="form-text--error">
                                {errors.date_end}
                              </span>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.buttons}>
                            <Button
                              variant="contained"
                              color="default"
                              onClick={() => resetForm(defaultState)}
                              className={classes.button}
                            >
                              เคลียร์
                        </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              onClick={handleSubmit}
                              className={classes.button}
                            >
                              ตกลง
                        </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </Paper>
                  </form>
                </Fragment>
              )}
          />
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(ShopApp)
