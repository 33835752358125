import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'

import * as actions from '../actions'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { formatDate, createCondition } from 'lib/helper'
import { emptySubRow, hasMessage } from 'lib/fxUI'
import debounce from 'lodash/debounce'

import { ActionBtn, ButtonTable, ConfirmDlg, SearchPanel, MenuMobile } from 'lib/widgets'

import MUIDataTable from 'mui-datatables'
import { MuiThemeProvider } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { Tablestyle } from '../../AddonStyles'
import AdvanceSearch from './AdvanceSearch'
import { subComponent as SubComponent } from './RelateFunc'
import { defaultState } from '../defaultState'
import { getGINextStatus } from '../../helpFx'

const DRAFT = 0,
  CONFIRM = 1,
  PARTIAL = 2

const checkIcon = (
  <i className="fa fa-check-circle" style={{ color: 'green' }} />
)
const crossIcon = (
  <i className="fa fa-exclamation-circle" style={{ color: 'red' }} />
)

const actionLink = (status, id, source_by, empty_stock_balance, onClick) => {
  let Btn = '',
    title = ''
  switch (status) {
    case DRAFT:
      title = 'Confirm'
      break
    case CONFIRM:
      title = 'Cancel'
      break
    case PARTIAL:
      title = 'Close'
      break
    default:
      break
  }

  if (source_by === 'SFA' && status === CONFIRM) {
    title = empty_stock_balance === 0 ? 'Issue' : 'Cancel'
  }

  if (title !== '') {
    return (
      <Link to="#" onClick={() => onClick(id, getGINextStatus(status))}>{title}</Link>
    )
  }

  return Btn
}

class GoodsIssueTable extends Component {
  state = {
    term: '',
    term_from_advsearch: "",
    search: '',
    delId: '',
    options: {},
    page: 0,
    sortName: '',
    rowsPerPage: 10,
    count: 100,
    isModalOpen: false,
    showAdvSearch: false,
    successSave: null,
    deleteSuccess: null,
    width: window.innerWidth,

  }

  componentDidMount() {
    this.fetchGoodsIssues()
  }

  componentWillReceiveProps(nextProps) {
    const { successSave, deleteSuccess } = nextProps
    this.setState({ successSave, deleteSuccess })
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  }

  showAdvSearch = state => {
    this.setState({ showAdvSearch: state })
  }

  fetchGoodsIssues = async () => {
    const { term } = this.state
    this.props.loadGoodsIssues(term)
  }

  delaySearchData = debounce(async value => {
    this.setState({ search: value })
    const cond = 'strict=false&' + createCondition({ order_no: '' }, value)
    this.props.loadGoodsIssues('?' + cond)
  }, 750)

  searchdata = event => {
    return this.delaySearchData(event)
  }

  advSearch = data => {
    this.props.loadGoodsIssues(data)
  }

  setAdvSearch = data => {
    this.setState({ term: data.replace(/\?/g, ''), term_from_advsearch : data.replace(/\?/g, '') })
    this.advSearch(data)
  }

  navigateToCreateForm = () => {
    this.props.history.push('/warehouse/goods-issues/new')
  }

  editForm = id => {
    this.props.history.push(`/warehouse/goods-issues/${id}`)
  }

  deleteGoodsIssue = id => {
    const { delId } = this.state
    this.props.deleteGoodsIssue(delId)
    this.setState({ isModalOpen: false })
  }

  toggleConfirmDlg = (id = '') => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      delId: id,
      successSave: null,
      deleteSuccess: null
    })
  }

  getRealColumn = name => {
    let col = ''

    switch (name) {
      case 'fullname':
        col = 'name'
        break
      case 'doctype':
        col = 'document_type_id'
        break
      case 'status_desc':
        col = 'status'
        break
      default:
        col = name
        break
    }

    return col
  }

  sortingData = (changedColumn, direction) => {
    let sortby = this.getRealColumn(changedColumn)
    const { page, rowsPerPage, search } = this.state
    const sort = direction === 'ascending' ? 'asc' : 'desc'
    let condStr = 'strict=false&' + createCondition({ order_no: '' }, search)
    if(this.state.term_from_advsearch !== "") condStr += "&"+this.state.term_from_advsearch
    
    let term = `?page=${page}&per_page=${rowsPerPage}&sort_by=${sortby}&sort=${sort}&${condStr}`

    this.setState(
      {
        term: term,
        sortName: changedColumn,
        options: { sort: true, sortDirection: sort }
      },
      this.advSearch(term)
    )
  }

  handleTableChange = (action, tableState) => {
    let term = ''
   // console.log("this.state.search", this.state.search, this.state.term)
    let condStr = 'strict=false&' + createCondition({ order_no: '' }, this.state.search)

    if(this.state.term_from_advsearch !== "") condStr += "&"+this.state.term_from_advsearch

    switch (action) {
      case 'changeRowsPerPage':
        term = `?per_page=${tableState.rowsPerPage}&${condStr}`
        this.setState(
          {
            term: term,
            rowsPerPage: tableState.rowsPerPage,
            page: 0
          },
          this.advSearch(term)
        )
        break
      case 'changePage':
        term = `?page=${tableState.page + 1}&per_page=${tableState.rowsPerPage}&${condStr}`
        this.setState(
          {
            term: term,
            page: tableState.page
          },
          this.advSearch(term)
        )
        break
      default:
        break
    }
  }

  handleClickRow = id => {
    let ele = document.getElementById(id)
    let show = ele.style.display === 'none' ? '' : 'none'
    ele.removeAttribute('style')
    ele.style.display = show
  }

  handleChangeStatus = (id, status) => {
    this.props.changeStatusGI(id, status)
  }

  resetSaveState = () => {
    this.setState({
      successSave: null,
      deleteSuccess: null
    }/*, this.fetchGoodsIssues*/)
  }

  renderTable = (isLoading, columns, goodsissues, optionsTable) => {
    const isMobile = this.state.width <= 500

    if (isMobile) {
      return <MenuMobile
        data={goodsissues}
        columns={{
          col1: 'delivery_date',
          col2: 'order_no',
          col3: 'source_by',
          col4: 'status_desc',
        }}
        title={{
          title1: () => 'ทำรายการล่าสุด',
          title2: (val) => val.empty_stock_balance > 0 ? crossIcon : checkIcon,
          title3: () => 'ที่มา',
          title4: () => 'สถานะ'
        }}
        url={`/api/goodsissues`}
        onLoad={this.advSearch}
        linkUrl={`/warehouse/goods-issues`}
        deleteOnly={false}
        onDelete={this.toggleConfirmDlg}
        deleteSuccess={this.props.deleteSuccess}
        actionLink={actionLink}
        changeStatus={this.handleChangeStatus}
      />
    }

    if (typeof goodsissues === 'object')
      return <Fragment>
        {isLoading && <div className="lmask" id="lmask" />}
        <MuiThemeProvider theme={Tablestyle()}>
          <MUIDataTable
            title={'ข้อมูลใบเบิกสินค้า'}
            data={goodsissues}
            columns={columns}
            options={optionsTable}
          />
        </MuiThemeProvider>
      </Fragment>

  }

  render() {
    const { isLoading, goodsissues, meta, errorMsg } = this.props
    const {
      rowsPerPage,
      page,
      sortName,
      options,
      successSave,
      deleteSuccess
    } = this.state
    const count = typeof meta === 'undefined' ? 100 : meta.total
    const columns = [
      {
        name: '',
        label: '#',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return page * rowsPerPage + tableMeta.rowIndex + 1
          }
        }
      },
      { label: 'เลขที่เอกสาร', name: 'order_no' },
      { label: 'เอกสารอ้างอิง', name: 'ref_no' },
      { label: 'ชนิดเอกสาร', name: 'document_type' },
      { label: 'ประเภทการตัดสต๊อก', name: 'process_type' },
      {
        label: 'ทำรายการล่าสุด',
        name: 'delivery_date',
        options: { customBodyRender: row => formatDate(row) }
      },
      { label: 'สถานะ', name: 'status_desc' },
      { label: 'ที่มา', name: 'source_by' },
      {
        label: ' ',
        name: 'empty_stock_balance',
        options: {
          customBodyRender: row => (row > 0 ? crossIcon : checkIcon)
        }
      },
      {
        label: ' ',
        name: 'id',
        options: {
          customBodyRender: (row, tableState) => {
            if (typeof tableState.rowData === 'object') {
              let val = tableState.rowData
              return actionLink(
                val[11],
                row,
                val[7],
                val[8],
                this.handleChangeStatus
              )
            }
          }
        }
      },
      {
        name: 'id',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            let val = tableMeta.rowData
            return (
              <ButtonTable
                id={value}
                onOpen={this.editForm}
                delDisable={val[11] !== 0 ? true : false}
                view="goodsissue delete"
                onDelete={() => this.toggleConfirmDlg(value)}
              />
            )
          }
        }
      },
      { label: '', name: 'status', options: { display: false } }
    ]

    const subRow = [
      { label: 'สินค้า', name: 'product_name' },
      { label: 'จำนวนที่สั่ง', name: 'order_qty' },
      { label: 'จำนวนที่จ่าย', name: 'accumulated_qty' },
      { label: 'จำนวนที่เหลือ', name: 'remain_qty' },
      { label: 'หน่วยนับ', name: 'uom_name' },
      { label: '', name: 'summary', show: false }
    ]

    const subRow2 = [
      { label: 'Batch', name: 'batch' },
      { label: 'จำนวนที่สั่ง', name: 'sum_order_qty' },
      { label: 'จำนวนที่จ่าย', name: 'sum_acc_qty' },
      { label: 'จำนวนที่เหลือ', name: 'sum_remain_qty' },
      { label: 'คลังสินค้า', name: 'warehouse_name' },
      { label: 'ที่จัดเก็บ', name: 'storage_name' },
      {
        label: 'วันที่ผลิต',
        name: 'manufacturing_date',
        Cell: row => formatDate(row.value)
      },
      {
        label: 'วันที่หมดอายุ',
        name: 'expired_date',
        Cell: row => formatDate(row.value)
      }
    ]

    const optionsTable = {
      filter: false,
      print: false,
      download: false,
      viewColumns: false,
      filterType: 'dropdown',
      serverSide: true,
      count: count,
      page: page,
      expandableRows: true,
      rowsPerPage: rowsPerPage,
      selectableRows: false,
      onTableChange: this.handleTableChange,
      onSearchChange: this.searchdata,
      onColumnSortChange: this.sortingData,
      onSearchClose: () => {
        this.setState({ search: '' })
        this.advSearch(this.state.term)
      },
      textLabels: {
        body: {
          noMatch: "Sorry, no matching records found",
          toolTip: "Sort",
        }
      },
      renderExpandableRow: (rowData, rowMeta) => {
        const items = goodsissues[rowMeta.dataIndex].relations.detailGroup
        const colSpan = rowData.length + 1
        if (items.length === 0) return emptySubRow(colSpan)
        return (
          <SubComponent
            colSpan={colSpan}
            subRow={subRow}
            items={items}
            rowMeta={rowMeta}
            subRow2={subRow2}
            handleClickRow={this.handleClickRow}
          />
        )
      },
      customToolbar: () => {
        return (
          <ActionBtn
            onCreate={this.navigateToCreateForm}
            onSearch={this.showAdvSearch}
            view="goodsissue edit"
          />
        );
      }
    }

    if (sortName !== '') {
      let idx = columns.findIndex(data => data.name === sortName)
      if (idx === -1) return
      columns[idx].options = options
    }

    return (
      <Fragment>
        <Fragment>
          {successSave !== null && hasMessage(successSave, errorMsg, this.resetSaveState)}
          {deleteSuccess !== null &&
            hasMessage(deleteSuccess, 'ลบข้อมูลสำเร็จ', this.resetSaveState)}
          {isLoading && <div className="lmask" id="lmask" />}
          <Grid container spacing={24}>
            <Grid item xs style={{ textAlign: 'right' }}>
              {crossIcon} สินค้าไม่ครบ &nbsp;&nbsp;&nbsp; {checkIcon}
              สินค้าครบ
                    </Grid>
            <Grid item md={12}>
              {this.renderTable(isLoading, columns, goodsissues, optionsTable)}
            </Grid>

          </Grid>

        </Fragment>

        <ConfirmDlg
          title="ยืนยันการลบข้อมูล"
          content="คุณต้องการลบข้อมูลนี้ ?"
          onToggle={this.toggleConfirmDlg}
          onConfirm={this.deleteGoodsIssue}
          isOpen={this.state.isModalOpen}
        />
        <SearchPanel
          toggleDrawer={this.showAdvSearch}
          isOpen={this.state.showAdvSearch}
          onSubmit={this.setAdvSearch}
          Component={AdvanceSearch}
          formValue={defaultState.advSearch}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ goodsissues }) => ({
  isLoading: goodsissues.isLoading,
  goodsissues: goodsissues.items,
  meta: goodsissues.meta,
  backupItems: goodsissues.backupItems,
  successSave: goodsissues.successSave,
  deleteSuccess: goodsissues.deleteSuccess,
  errorMsg: goodsissues.errorMsg
})

const mapDispatchToProps = {
  changeStatusGI: actions.changeStatusGI.request,
  loadGoodsIssues: actions.loadGoodsIssues.request,
  deleteGoodsIssue: actions.deleteGoodsIssue.request,
  filterData: actions.filterData
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(GoodsIssueTable)
